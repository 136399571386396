import { useTenantTheme } from '@web/contexts/TenantThemeContext';


const Logo = () => {
  const [tenant] = useTenantTheme();
  return (
    <img src={tenant.logoBanner} alt="Facility Logo" style={{ maxWidth: '100%', maxHeight: '40px', padding: '0 10px' }} />
  );
};

export default Logo;
