import { Box } from '@mui/material';
import { LegoProps } from '@ropha/builder';

const Dot = ({ color, size = 8 }: LegoProps['Dot']) => {
  return (
    <Box
      sx={{
        width: size,
        height: size,
        borderRadius: '50%',
        bgcolor: `${color}.main`
      }}
    />
  );
};

export default Dot;
