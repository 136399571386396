import { LegoProps } from '@ropha/builder';
import { Box, Divider, Grid, Stack, Step, StepLabel, Stepper, useMediaQuery } from '@mui/material';
import { GradientStepIcon } from '@web/components/atoms/GradientStepIcon';
import { useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Connector } from '@web/components/molecules/Wizard/Connector';
import { EnhancedForms } from '@web/components/molecules/EnhancedForms';
import { AntdIcon } from '@web/components/atoms/AntdIcon';
import { Controls } from '@web/components/molecules/Wizard/Controls';
import Text from '@web/components/atoms/displays/Text';

export function Wizard({ labels, icons, showIndex, loading, content, onComplete }: LegoProps['Wizard']) {
  const theme = useTheme();
  const smAndBelow = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isLastStep = activeStep === labels.length - 1;

  const config = useMemo(() => content[activeStep], [content, activeStep]);

  return (
    <Grid item xs={12}>
      <Stack
        direction={smAndBelow ? 'column' : 'row'}
        spacing={{ xs: 4, sm: 6, md: 10, lg: 15 }}
      >
        <Box width={smAndBelow ? '100%' : 120} pl={smAndBelow ? 0 : 4}>
          <Stepper alternativeLabel={smAndBelow}
                   orientation={smAndBelow ? 'horizontal' : 'vertical'}
                   activeStep={activeStep}
                   connector={<Connector />}
          >
            {labels.map((label, index) => <Step key={label}>
              <StepLabel
                StepIconComponent={GradientStepIcon}
                StepIconProps={{ icon: <AntdIcon name={icons[index]} /> }}
              >
                {label}
              </StepLabel>
            </Step>)}
          </Stepper>
        </Box>
        <Grid item xs={12} md={8} lg={6} px={2} pt={0}>
          <Stack spacing={2} width={'100%'}>
            {showIndex && <Text variant="caption" content={`Step ${activeStep + 1}`} />}

            {config &&
              <>
                {config.title && <Text variant="heading" content={config.title} />}

                {config.description && <Text variant="paragraph" content={config.description} />}

                <Box sx={{ ml: 10 }}>
                  <EnhancedForms forms={config.forms} value={config.value} onChange={config.onChange} />
                </Box>
              </>
            }

            <Divider sx={{ marginTop: 30 }} />
            <Controls
              isLastStep={isLastStep}
              stepIndex={activeStep}
              loading={loading}
              onNext={handleNext}
              onBack={handleBack}
              onComplete={() => onComplete && onComplete()}
            />
          </Stack>
        </Grid>
      </Stack>
    </Grid>
  )
    ;
}
