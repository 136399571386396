import { Box, Chip, Grid, Stack, Typography } from '@mui/material';

import MainCard from '@web/components/atoms/cards/MainCard';

import { FallOutlined, RiseOutlined } from '@ant-design/icons';
import { LegoProps } from '@ropha/builder';


const InsightCard = ({ color = 'primary', title, count, percentage, isLoss, extra }: LegoProps['InsightCard']) => (
  <MainCard contentSX={{ p: 2.25 }}>
    <Stack spacing={0.5}>
      <Typography variant="h6" color="textSecondary">
        {title}
      </Typography>
      <Grid container alignItems="center">
        <Grid item>
          <Typography variant="h4" color="inherit">
            {count}
          </Typography>
        </Grid>
        {percentage && (
          <Grid item>
            <Chip
              variant="filled"
              color={color as any}
              icon={
                <>
                  {!isLoss && <RiseOutlined style={{ fontSize: '0.75rem', color: 'inherit' }} />}
                  {isLoss && <FallOutlined style={{ fontSize: '0.75rem', color: 'inherit' }} />}
                </>
              }
              label={`${percentage}%`}
              sx={{ ml: 1.25, pl: 1 }}
              size="small"
            />
          </Grid>
        )}
      </Grid>
    </Stack>

    <Box sx={{ pt: 2.25 }}>
      {extra ?
        <Typography variant="caption" color="textSecondary">
          There was difference of {' '}
          <Typography component="span" variant="caption" sx={{ color: `${color || 'primary'}.main` }}>
            {extra}
          </Typography>{' '}
        </Typography> :
        <Typography variant="caption" color="textSecondary">{' . '}</Typography>
      }
    </Box>
  </MainCard>
);

export default InsightCard;
