import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';

export const PageLoader = () => {
  return (
    <div className="content vh-100 vw-100 d-flex align-items-center justify-content-center">
      <div className="font-14">Verifying Session...</div>
    </div>
  );
};

export const AuthGuard = ({ component }: { component: any }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <PageLoader />
  });

  return <Component />;
};
