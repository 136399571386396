import { action, makeObservable, observable } from 'mobx';
import { OfferingEntity } from '@web/gen/api';
import { api } from '@web/hooks/useApi';
import { call } from '@web/utils/api.utils';

export class OfferingsViewModel {

  @observable services: OfferingEntity[] = [];
  @observable specialities: OfferingEntity[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  async loadServices() {
    this.services = await call(api.offerings.services);
  }

  @action
  async loadSpecialities() {
    this.specialities = await call(api.offerings.specialities);
  }

  async searchServices(input: string) {
    if (!this.services?.length) await this.loadServices();
    if (input.length && this.services?.length) {
      return this.services.filter(u =>
        u.name.toLowerCase().includes(input.toLowerCase()) ||
        u.description.toLowerCase().includes(input.toLowerCase())
      );
    }
    return this.services;
  }

  async searchSpecialities(input: string) {
    if (!this.specialities?.length) await this.loadSpecialities();
    if (input.length && this.specialities?.length) {
      return this.specialities.filter(u =>
        u.name.toLowerCase().includes(input.toLowerCase()) ||
        u.description.toLowerCase().includes(input.toLowerCase())
      );
    }
    return this.specialities;
  }
}
