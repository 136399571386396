import { TableCell, TableRow } from '@mui/material';
import { WithLego } from '@web/components/organisms/WithLego';

export function EnhancedTableRow({ cells, children }: { cells: any[]; children?: React.ReactNode }) {
  return (
    <TableRow hover role="checkbox" sx={{ '&:last-child td, &:last-child th': { border: 0 } }} tabIndex={-1}>
      {cells.map((cell, index) => (
        <TableCell key={index}>
          <WithLego lego={cell} />
        </TableCell>
      ))}
      {children}
    </TableRow>
  );
}
