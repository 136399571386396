import { Button, Stack } from '@mui/material';
import { CheckOutlined, SwapLeftOutlined, SwapRightOutlined } from '@ant-design/icons';
import { LoadingButton } from '@mui/lab';

export type ControlsProps = {
  stepIndex: number;
  isLastStep: boolean;
  loading?: boolean;
  onBack: () => void;
  onNext: () => void;
  onComplete: () => void;
}

export function Controls({ stepIndex, isLastStep, loading, onNext, onBack, onComplete }: ControlsProps) {
  return (
    <Stack direction="row" spacing={3}>
      <Button
        onClick={onBack}
        disabled={stepIndex === 0 || loading}
        color="secondary"
        variant="contained"
        disableElevation
        startIcon={<SwapLeftOutlined />}
      >
        Back
      </Button>
      {isLastStep ?
        <LoadingButton
          loading={loading}
          onClick={onComplete}
          variant="contained"
          color="success"
          disableElevation sx={{ borderRadius: 0 }}
          endIcon={<CheckOutlined />}
        >
          Complete
        </LoadingButton> :
        <LoadingButton
          loading={loading}
          onClick={onNext}
          variant="contained"
          disableElevation
          sx={{ borderRadius: 0 }}
          endIcon={<SwapRightOutlined />}
        >
          Next Step
        </LoadingButton>
      }
    </Stack>
  );
}
