import { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import ReactApexChart from 'react-apexcharts';
import { Box, Grid, Typography } from '@mui/material';
import MainCard from '@web/components/atoms/cards/MainCard';
import { LegoProps } from '@ropha/builder';

const AreaChart = ({ title, series, categories }: LegoProps['AreaChart']) => {
  const theme = useTheme();

  const secondary = theme.palette.text.secondary;
  const line = theme.palette.divider;

  const options = useMemo<ApexCharts.ApexOptions>(() => ({
    chart: { height: 450, type: 'area', toolbar: { show: false } },
    dataLabels: { enabled: false },
    stroke: { curve: 'smooth', width: 2 },
    grid: { strokeDashArray: 0, borderColor: line },
    colors: [theme.palette.primary.main, theme.palette.primary[700]],
    xaxis: {
      categories,
      labels: { style: { colors: secondary } },
      axisBorder: { show: true, color: line },
      tickAmount: 7
    },
    yaxis: {
      labels: { style: { colors: secondary } }
    },
    tooltip: { theme: 'light' }
  }), [theme]);

  return (
    <>
      {!!title &&
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5">{title}</Typography>
          </Grid>
        </Grid>
      }
      <MainCard content={false} sx={{ mt: 1.5 }}>
        <Box sx={{ pt: 1, pr: 2 }}>
          <ReactApexChart options={options} series={series} type="area" height={450} />
        </Box>
      </MainCard>
    </>
  );
};

export default AreaChart;
