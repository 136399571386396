import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import Breadcrumbs from '@web/components/atoms/Breadcrumbs';

type BreadcrumbsProps = {
  navigation: any[]
}

const NavBreadcrumbs = ({ navigation }: BreadcrumbsProps) => {
  const location = useLocation();
  const [item, setItem] = useState<string>();

  useEffect(() => {
    const menuItem = navigation.filter((menu: any) => menu.type === 'group')
      .flatMap((menu: any) => menu.children || [])
      .find((collapse: any) => collapse.type === 'item' && location.pathname === collapse.url);
    setItem(menuItem?.title);
  });

  return item ? <Breadcrumbs items={['Home', item]} /> : <Typography />;
};

export default NavBreadcrumbs;
