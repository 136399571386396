import React from 'react';
import Text from '@web/components/atoms/displays/Text';
import DataTable from '@web/components/molecules/DataTable';
import { Lego } from '@ropha/builder';
import ListItem from '@web/components/atoms/displays/ListItem';
import InsightCard from '@web/components/atoms/cards/InsightCard';
import { StatusChip } from '@web/components/atoms/displays/StatusChip';
import ProgressBar from '@web/components/atoms/displays/ProgressBar';
import { TextInput } from '@web/components/atoms/inputs/TextInput';
import { ToggleInput } from '@web/components/atoms/inputs/ToggleInput';
import { InputArray } from '@web/components/molecules/InputArray';
import { Wizard } from '@web/components/molecules/Wizard';
import { InputGroup } from '@web/components/molecules/InputGroup';
import { FileInput } from '@web/components/atoms/inputs/FileInput';
import CalendarInput from '@web/components/atoms/inputs/CalendarInput';
import Dot from '@web/components/atoms/displays/Dot';
import { SelectInput } from '@web/components/atoms/inputs/SelectInput';
import { AreaInput } from '@web/components/atoms/inputs/AreaInput';
import ListCard from '@web/components/atoms/cards/ListCard';
import AreaChart from '@web/components/atoms/charts/AreaChart';
import SupportCard from '@web/components/atoms/cards/SupportCard';

export const Components: Record<Lego['name'], (...args: any[]) => React.ReactNode> = {
  DataTable,
  InsightCard,
  ListItem,
  Text,
  StatusChip,
  ProgressBar,
  TextInput,
  ToggleInput,
  InputArray,
  Wizard,
  InputGroup,
  FileInput,
  CalendarInput,
  Dot,
  SelectInput,
  AreaInput,
  ListCard,
  AreaChart,
  SupportCard
};
