// assets
import {
  AuditOutlined, ContainerOutlined,
  DashboardOutlined, DollarOutlined,
  IdcardOutlined, InsuranceOutlined,
  ReconciliationOutlined,
  RocketOutlined
} from '@ant-design/icons';

const menus = {
  id: 'group-finances',
  title: 'Finances',
  type: 'group',
  children: [
    {
      id: 'statements',
      title: 'Statements',
      type: 'item',
      url: '/finances/statements',
      icon: ContainerOutlined,
      breadcrumbs: true,
      fab: false,
      resource: 'statements',
    },
    {
      id: 'claims',
      title: 'Claims',
      type: 'item',
      url: '/finances/claims',
      icon: InsuranceOutlined,
      breadcrumbs: true,
      fab: false,
      resource: 'claims',
    },
    {
      id: 'reconciliations',
      title: 'Reconciliations',
      type: 'item',
      url: '/finances/reconciliations',
      icon: DollarOutlined,
      breadcrumbs: true,
      true: false,
      resource: 'reconciliations',
    }
  ]
};

export default menus;
