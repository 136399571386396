import { LegoBuilder } from '@ropha/builder';
import { OFFERING_DROPDOWN } from '@web/utils/dropdown.renderers';
import { OfferingEntity } from '@web/gen/api';

export const ServiceCostingForm = (label: string, offeringType: string, getOptions: (_: string) => Promise<OfferingEntity[]>) =>
  LegoBuilder.InputArray()
    .label(label)
    .input(
      LegoBuilder.InputGroup()
        .inputs({
          offering: LegoBuilder.SelectInput().grid({ xs: 8, md: 9 })
            .label(offeringType)
            .renderer(OFFERING_DROPDOWN)
            .getOptions(getOptions)
            .getLabel((value: OfferingEntity) => value.name)
            .build(),
          cost: LegoBuilder.TextInput().grid({ xs: 4, md: 3 })
            .label('Cost').build()
        }).build()
    )
    .defaultValue(() => ({ offering: null, cost: '1.00' }))
    .build();

export const SpecialityForm = (label: string, offeringType: string, getOptions: (_: string) => Promise<OfferingEntity[]>) =>
  LegoBuilder.InputArray()
    .label(label)
    .input(
      LegoBuilder.InputGroup()
        .inputs({
          offering: LegoBuilder.SelectInput().grid({ xs: 8, md: 9 })
            .label(offeringType)
            .renderer(OFFERING_DROPDOWN)
            .getOptions(getOptions)
            .getLabel((value: OfferingEntity) => value.name)
            .build(),
          cost: LegoBuilder.TextInput().grid({ xs: 4, md: 3 })
            .label('Cost').build()
        }).build()
    )
    .defaultValue(() => ({ offering: null, cost: '1.00' }))
    .build();
