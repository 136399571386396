import { FC, useEffect, useState } from 'react';
import { useMvvm } from '@web/contexts/MvvmContext';
import { useAuth0 } from '@auth0/auth0-react';
import { useDocumentTitle, useFavicon } from '@uidotdev/usehooks';
import LinearProgress from '@mui/material/LinearProgress';
import ForbiddenPage from '@web/pages/auth/ForbiddenPage';
import { useTenantTheme } from '@web/contexts/TenantThemeContext';


export const withUserLoading = (WrappedComponent: FC) => {
  return () => {
    const { isAuthenticated } = useAuth0();
    const mvvm = useMvvm();
    const [tenant, setTenant] = useTenantTheme();
    useDocumentTitle(tenant.title);
    useFavicon(tenant.logoSquare || tenant.logoBanner);

    const [ready, setReady] = useState(false);

    useEffect(() => {
      if (!isAuthenticated) return;
      mvvm.user.loadUser().then(() => {
        if (mvvm.user.tenantTheme)
          setTenant(mvvm.user.tenantTheme);
        setReady(true);
      });
      mvvm.notification.load();
    }, [isAuthenticated]);

    return ready ?
      mvvm.user.assumesRole('PATIENT') ?
        <ForbiddenPage /> :
        <WrappedComponent /> :
      <LinearProgress />;
  };
};
