import { Legos } from './Legos';
import { Prop } from './Prop';
import { createNestedBuilder } from '../../lib/builder';
import { GridProps } from '@mui/material';

export * from './Legos';

export type LegoProps = { [K in keyof Legos]: Prop<Legos[K]> };
export type Lego = Legos[keyof Legos]
export type GridLegos = { [K in keyof Legos]: Legos[K] & { grid?: Pick<GridProps, 'xs' | 'sm' | 'md' | 'lg' | 'xl'> } }
export type GridLego = GridLegos[keyof GridLegos]

export const LegoBuilder = createNestedBuilder<GridLegos, false>(name => ({ name }));
