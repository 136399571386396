import { TableBody, TableCell } from '@mui/material';
import { EnhancedTableHead } from '@web/components/atoms/tables/EnhancedTableHead';
import { EnhancedTableRow } from '@web/components/atoms/tables/EnhancedTableRow';
import EnhancedTable from '@web/components/atoms/tables/EnhancedTable';
import { LegoProps } from '@ropha/builder';
import MainCard from '@web/components/atoms/cards/MainCard';
import ActionButton from '@web/components/atoms/tables/ActionButton';

export default function DataTable({ cells, records, renderer, actions, maxHeight, title, onAction }: LegoProps['DataTable']) {
  cells = (cells && actions) ? [...cells, ''] : cells;
  return (
    <MainCard title={title} content={false}>
      <EnhancedTable maxHeight={maxHeight}>
        {cells && <EnhancedTableHead cells={cells} />}
        <TableBody>
          {records.map((record, index) => (
            <RenderedRow record={record} renderer={renderer} actions={actions} key={index} onAction={onAction} />
          ))}
        </TableBody>
      </EnhancedTable>
    </MainCard>
  );
}

function RenderedRow(
  { record, renderer, actions, onAction }: Pick<LegoProps['DataTable'], 'renderer' | 'actions' | 'onAction'> & { record: any }
) {
  return (
    <EnhancedTableRow cells={renderer(record)}>
      {actions &&
        <TableCell>
          {Object.entries(actions).map(([icon, color]) =>
            <ActionButton icon={icon} color={color} key={icon} onClick={() => onAction && onAction(icon, record)} />)
          }
        </TableCell>
      }
    </EnhancedTableRow>
  );
}
