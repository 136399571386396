import dashboard from './dashboard';
import support from './support';
import users from '@web/data/menus/users';
import activities from '@web/data/menus/activities';
import finances from '@web/data/menus/finances';
import onboarding from '@web/data/menus/onboarding';
import appointments from '@web/data/menus/appointments';


const menuItems = {
  items: [dashboard, appointments, onboarding, users, activities, finances, support]
};

export default menuItems;
