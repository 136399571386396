import {
  Avatar,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';
import MainCard from '@web/components/atoms/cards/MainCard';
import { AntdIcon } from '@web/components/atoms/AntdIcon';
import { LegoProps } from '@ropha/builder';
import { Link as RouterLink } from 'react-router-dom';


function ListCard({ title, items = [] }: LegoProps['ListCard']) {
  return (
    <>
      {!!title &&
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5">{title}</Typography>
          </Grid>
          <Grid item />
        </Grid>
      }
      <MainCard sx={{ mt: 2, width: '100%' }} content={false}>
        <List
          component="nav"
          sx={{
            px: 0, py: 0,
            '& .MuiListItemButton-root': {
              py: 1.5,
              '& .MuiAvatar-root': {
                width: 36,
                height: 36,
                fontSize: '1rem'
              },
              '& .MuiListItemSecondaryAction-root': {
                mt: 0.75,
                ml: 1,
                top: 'auto',
                right: 'auto',
                alignSelf: 'flex-start',
                transform: 'none',
                position: 'relative'
              }
            }
          }}
        >
          {items.map(
            (item, index) => (
              <ListItemButton divider key={index} component={item.url ? RouterLink : 'div'} to={item.url}>
                {
                  !!item.icon &&
                  <ListItemAvatar>
                    <Avatar sx={{ color: `${item.color || 'info'}.main`, bgcolor: `${item.color || 'info'}.lighter` }}>
                      <AntdIcon name={item.icon} />
                    </Avatar>
                  </ListItemAvatar>
                }
                <ListItemText
                  primary={<Typography variant="subtitle1">{item.primaryLabel}</Typography>}
                  secondary={item.altLabel}
                />
                <ListItemSecondaryAction>
                  <Stack alignItems="flex-end">
                    <Typography variant="subtitle1" noWrap>{item.primaryValue}</Typography>
                    {!!item.altValue &&
                      <Typography variant="h6" color="secondary" noWrap>{item.altValue}</Typography>
                    }
                  </Stack>
                </ListItemSecondaryAction>
              </ListItemButton>
            )
          )}
        </List>
      </MainCard>
    </>
  );
}

export default ListCard;
