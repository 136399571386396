import { Api } from '@web/gen/api';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useLoadingCallback } from 'react-loading-hook';
import { call } from '@web/utils/api.utils';
import { ASSUMED_ROLE_HEADER, ASSUMED_ROLE_STORAGE_KEY } from '@web/utils/constants';

export const api = new Api<() => Promise<string>>({
  secure: true,
  baseURL: import.meta.env.VITE_API_URL,
  securityWorker: async (accessToken: (() => Promise<string>) | null) => {
    const headers = { [ASSUMED_ROLE_HEADER]: localStorage.getItem(ASSUMED_ROLE_STORAGE_KEY), 'Authorization': '' };
    if (accessToken) headers['Authorization'] = `Bearer ${await accessToken()}`;
    return { headers };
  }
});

export const useApi: typeof useLoadingCallback = <T extends Parameters<typeof useLoadingCallback>[0]>(callFn: T) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    api.setSecurityData(() => getAccessTokenSilently({
      authorizationParams: { audience: import.meta.env.VITE_AUTH0_AUDIENCE }
    }));
  }, [isAuthenticated, getAccessTokenSilently]);

  return useLoadingCallback(async (...args: any) => call(callFn, ...args));
};
