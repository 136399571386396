import { getMessaging, getToken, onMessage } from '@firebase/messaging';
import { initializeApp } from '@firebase/app';
import { NotificationEntity } from '@web/gen/api';

const firebaseConfig = {
  projectId: 'ropha-telemedicine',
  apiKey: 'AIzaSyCvTjTPUagW-i-c-HM7MWtuodP3lY2VpvA',
  authDomain: 'ropha-telemedicine.firebaseapp.com',
  storageBucket: 'ropha-telemedicine.appspot.com',
  messagingSenderId: '606103963274',
  appId: '1:606103963274:web:6f8d1aab2ffbacb3018f73',
  measurementId: 'G-CXTHP7YQ82'
};

const firebaseApp = initializeApp(firebaseConfig);

export const messaging = getMessaging(firebaseApp);

export const getFCMToken = () => getToken(messaging);

export const setupNotifications = async (
  cb: (type: NotificationEntity['type'], _: NotificationEntity['payload'], $: NotificationEntity['priority']) => void
) => {
  try {
    onMessage(messaging, (payload) => {
      const data = payload.data as any;
      console.log('Message:', data);
      cb(data.type, JSON.parse(data.payload), data.priority);
    });
  } catch (error) {
    console.error('Error setting up notifications:', error);
  }
};
