import { LegoProps } from '@ropha/builder';
import { WithLego } from '@web/components/organisms/WithLego';
import React from 'react';
import { Button, Grid, Stack } from '@mui/material';
import ActionButton from '@web/components/atoms/tables/ActionButton';
import { AntdIcon } from '@web/components/atoms/AntdIcon';
import Text from '@web/components/atoms/displays/Text';

export function InputArray({ input, value = [], label, onChange, defaultValue }: LegoProps['InputArray']) {

  function changeHandler(index: number, input: any) {
    const updatedValue = [...value];
    updatedValue.splice(index, 1, input);
    onChange && onChange(updatedValue);
  }

  const addRecord = () => {
    if (!defaultValue) return;
    const updatedValue = [...value, defaultValue()];
    onChange && onChange(updatedValue);
  };

  const removeRecord = (index: number) => {
    const updatedValue = [...value];
    updatedValue.splice(index, 1);
    onChange && onChange(updatedValue);
  };

  return (
    <>
      {label &&
        <Stack width="100%" justifyContent="end" mt={4} ml={2}>
          <Text variant="paragraph-bold" content={label.toUpperCase()} />
        </Stack>
      }
      {!!value?.length &&
        <Stack spacing={2} width="100%" mt={4} ml={2}>
          {value.map((v, index) => (
            <Stack direction="row" alignItems="center" key={index}>
              <ActionButton icon="CloseOutlined" color="error" onClick={() => removeRecord(index)} />
              <Grid container spacing={2} sx={{ ml: 0.5 }} flexGrow={1}>
                <WithLego
                  grid={input.grid}
                  lego={{ ...input, value: v, onChange: (v: any) => changeHandler(index, v) }}
                />
              </Grid>
            </Stack>
          ))}
        </Stack>
      }
      {defaultValue &&
        <Stack direction="row" justifyContent="end" mt={2} ml={2}>
          <Button size="small" color="primary" startIcon={<AntdIcon name="PlusOutlined" />} onClick={addRecord}>
            ADD
          </Button>
        </Stack>
      }
    </>
  );
}
