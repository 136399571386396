import { LegoBuilder } from '@ropha/builder';
import { OfferingEntity, User } from '@web/gen/api';
import { DisplayUtils } from '@web/utils/display.utils';

export const USER_DROPDOWN = (user: User) => LegoBuilder.ListItem()
  .primaryText(DisplayUtils.fullName(user))
  .secondaryText(user.email)
  .avatar(user.profilePicture)
  .build();

export const OFFERING_DROPDOWN = (option: OfferingEntity) => LegoBuilder.ListItem()
  .primaryText(option.name)
  .secondaryText(DisplayUtils.truncated(option.description, 70))
  .build();
