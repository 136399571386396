import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import { LegoProps } from '@ropha/builder';
import { DisplayUtils } from '@web/utils/display.utils';
import { typographyClasses, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMemo, useRef } from 'react';
import { css, cx } from '@emotion/css';
import { Availability } from '@web/data/models/Availability';


const color = 'rgba(216,55,111,0.5)';


export default function CalendarInput({ onChange, value = [] }: LegoProps['CalendarInput']) {
  const theme = useTheme();
  const smAndBelow = useMediaQuery(theme.breakpoints.down('sm'));
  const ref = useRef<FullCalendar>(null);

  const events = useMemo(() => value.map(
    ({ day, startTime, endTime }: Availability) => ({
      daysOfWeek: [day], startTime, endTime, color
    })
  ), [value]);

  function addSelection(start: Date, end: Date) {
    onChange([
      ...value,
      { day: start.getDay(), startTime: start.toTimeString(), endTime: end.toTimeString() }
    ]);
  }

  function updateEvents() {
    if (ref.current) {
      const newValue = ref.current?.getApi().getEvents().map(({ start, end }) =>
        ({ day: start!.getDay(), startTime: start!.toTimeString(), endTime: end!.toTimeString() }));
      onChange(newValue);
    }
  }

  return (
    <FullCalendar
      ref={ref}
      plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, resourceTimeGridPlugin]}
      initialView={smAndBelow ? 'listWeek' : 'timeGridWeek'}
      headerToolbar={false}
      weekNumbers={false}
      dayHeaders={true}
      dayHeaderContent={({ date }) => DisplayUtils.toWeekDayShort(date)}
      allDaySlot={false}
      eventResizableFromStart={true}
      selectOverlap={false}
      select={({ start, end }) => addSelection(start, end)}
      eventResize={updateEvents}
      eventOverlap={false}
      selectable={true}
      events={events}
      nowIndicator={false}
      editable
      eventTimeFormat={{ hour: 'numeric', minute: 'numeric', meridiem: true }}
      dayHeaderClassNames={
        cx(
          css`
              background-color: ${theme.palette.grey['200']};
              text-transform: uppercase;
              font-size: 12px;
              width: unset;
              padding: ${theme.spacing(1.5, 1)} !important;
          `
        )
      }
      slotLabelClassNames={[typographyClasses.subtitle2]}
    />
  );
}
