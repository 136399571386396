import { DateTime, Interval } from 'luxon';
import { User } from '@web/gen/api';

const NUMBER_FORMAT = new Intl.NumberFormat(
  'en-IN',
  {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }
);

export const DisplayUtils = {

  fullName(user: Partial<Pick<User, 'title' | 'firstName' | 'lastName' | 'otherNames'>>) {
    return `${user.title || ''} ${user.firstName || ''} ${user.lastName || ''} ${user.otherNames || ''}`.trim();
  },

  toHumanDate(date: Date) {
    return DateTime.fromJSDate(date).toRelativeCalendar() || '';
  },

  toHumanDateFromString(date: string) {
    return DateTime.fromISO(date).toRelativeCalendar() || '';
  },

  toNotification(date: string) {
    return DateTime.fromISO(date).toRelativeCalendar() || '';
  },

  toWeekDay(date: Date) {
    return DateTime.fromJSDate(date).weekdayLong;
  },

  toWeekDayShort(date: Date) {
    return DateTime.fromJSDate(date).weekdayShort;
  },

  truncated(str: string | undefined, limit: number) {
    return str && str.length > limit ? str.slice(0, limit) + '...' : str || '';
  },

  addMinutes(date: Date, minute: number) {
    return DateTime.fromJSDate(date).plus({ minute }).toJSDate();
  },

  toDateString(date: string): string {
    return new Date(date).toDateString();
  },

  toTimeString(date: string): string {
    return new Date(date).toLocaleTimeString();
  },

  toMoney(amount: number) {
    return `GH₵ ${NUMBER_FORMAT.format(amount)}`;
  },

  durationBetween(from: string, to: string) {
    return Interval
      .fromDateTimes(
        DateTime.fromISO(from),
        DateTime.fromISO(to)
      )
      .toDuration(['hour', 'minute'], { conversionAccuracy: 'casual' })
      .toHuman({ listStyle: 'narrow', unitDisplay: 'narrow' });
  },

  enumToHuman(userType: string, lowercase?: boolean) {
    const text = userType.replace(/[^a-z]/i, ' ');
    return lowercase ? text.toLowerCase() : text;
  }
};
