import { Box, Dialog, DialogContent, DialogTitle, Divider, IconButton } from '@mui/material';
import { AntdIcon } from '@web/components/atoms/AntdIcon';

export type EnhancedDialogProps = {
  title?: string
  open: boolean
  loading?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg'
  onCancel?: () => void
  children?: React.ReactNode
}

export default function EnhancedDialog(
  { title, open, loading, size = 'md', children, onCancel }: EnhancedDialogProps
) {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      fullWidth
      maxWidth={size}
      PaperProps={{
        component: 'form'
      }}
    >
      <>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>{title || ''}</span>
          <IconButton onClick={onCancel} disabled={loading} color={'error'}>
            <AntdIcon name={'CloseCircleOutlined'} />
          </IconButton>
        </DialogTitle>
        <Divider />
      </>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', m: 'auto' }}>
          {children}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
