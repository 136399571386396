import {
  Avatar,
  CardContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Stack,
  Typography
} from '@mui/material';
import { LogoutOutlined } from '@ant-design/icons';
import { useAuthUserName } from '@web/hooks/useAuthUserName';
import { useAuthUserProfilePic } from '@web/hooks/useAuthUserProfilePic';
import { useAuth0 } from '@auth0/auth0-react';
import { useMvvm } from '@web/contexts/MvvmContext';
import { observer } from 'mobx-react-lite';
import { useAuthUserEmail } from '@web/hooks/useAuthUserEmail';

function Tenants() {
  const { logout } = useAuth0();

  const name = useAuthUserName();
  const email = useAuthUserEmail();
  const profilePic = useAuthUserProfilePic();

  const mvvm = useMvvm();

  return (
    <>
      <CardContent sx={{ px: 2.5, pt: 3, pb: 0 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Stack direction="row" spacing={1.25} alignItems="center">
              <Avatar alt="profile user" src={profilePic} sx={{ width: 32, height: 32 }} />
              <Stack>
                <Typography variant="h6">{name}</Typography>
                <Typography variant="body2" color="textSecondary">{email}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item>
            <IconButton size="large" color="secondary" onClick={() => logout()}>
              <LogoutOutlined />
            </IconButton>
          </Grid>
        </Grid>
        <Divider sx={{ mt: 1 }} />
      </CardContent>
      {mvvm.user.facilities.length ?
        <List
          dense
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="tenant-list"
          subheader={
            <ListSubheader id="tenant-list" sx={{ fontSize: 12, height: 36 }}>
              Switch Facilities
            </ListSubheader>
          }
        >
          {
            mvvm.user.facilities.map((facility) => (
              <ListItemButton
                href={`https://${facility.slug}.${import.meta.env.VITE_FACILITY_TLD}`}>
                <ListItemAvatar>
                  <Avatar alt={`${facility.name} logo`} src={facility.logo} />
                </ListItemAvatar>
                <ListItemText primary={facility.name} secondary={facility.address} />
              </ListItemButton>
            ))
          }
        </List> :
        null
      }
    </>
  );
}

export default observer(Tenants);
