import { LegoBuilder } from '@ropha/builder';

export const InviteUserForm = LegoBuilder.InputGroup()
  .inputs({
    firstName: LegoBuilder.TextInput().grid({ xs: 12, sm: 6 })
      .label('First Name').build(),
    lastName: LegoBuilder.TextInput().grid({ xs: 12, sm: 6 })
      .label('Last Name').build(),
    email: LegoBuilder.TextInput().grid({ xs: 12, sm: 7 })
      .label('Email Address').build(),
    phoneNumber: LegoBuilder.TextInput().grid({ xs: 12, sm: 5 })
      .label('Phone Number').build()
  })
  .build();
