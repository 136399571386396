// assets
import { ChromeOutlined, QuestionOutlined } from '@ant-design/icons';

const icons = {
  ChromeOutlined,
  QuestionOutlined
};

const support = {
  id: 'support',
  title: 'Support',
  type: 'group',
  children: [
    {
      id: 'documentation',
      title: 'Walkthrough Tutorials',
      type: 'item',
      url: 'https://app.storylane.io/share/mzfaxu6w0vfs',
      icon: icons.QuestionOutlined,
      external: true,
      target: true
    },
    {
      id: 'sample-page',
      title: 'Customer Support',
      type: 'item',
      url: 'https://forms.gle/Hoe6ZsqUm8XEvkNb7',
      external: true,
      target: true,
      icon: icons.ChromeOutlined
    },
  ]
};

export default support;
