import MinimalLayout from '@web/layouts/MinimalLayout';
import AuthCallbackPage from '@web/pages/auth/CallbackPage';

const LoginRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    { path: 'callback', element: <AuthCallbackPage /> }
  ]
};

export default LoginRoutes;
