import Routes from '@web/routes';
import ThemeCustomization from '@web/themes';
import ScrollTop from '@web/components/ScrollTop';
import { Flip, ToastContainer } from 'react-toastify';
import TenantThemeProvider from '@web/contexts/TenantThemeContext';
import { ModalProvider } from '@web/contexts/ModalContext';
import { withProfiler } from '@sentry/react';


const App = () => (
  <TenantThemeProvider>
    <ThemeCustomization>
      <ScrollTop>
        <ModalProvider>
          <Routes />
        </ModalProvider>
        <ToastContainer theme="light" transition={Flip} />
      </ScrollTop>
    </ThemeCustomization>
  </TenantThemeProvider>
);

export default import.meta.env.VITE_SENTRY_ENABLED === 'true' ? withProfiler(App) : App;
