import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import Logo from '@web/components/atoms/Logo';
import { Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';

export default function ForbiddenPage() {
  const { error, isAuthenticated, logout } = useAuth0();

  return (
    <Container sx={{ my: 5, pt: 5 }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8} lg={6}>
          <Card elevation={0} sx={{backgroundColor: 'transparent'}}>
            <CardContent>
              <Grid container justifyContent="center" sx={{ mb: 6, mt: 3 }}>
                <a href="/">
                  <Logo />
                </a>
              </Grid>
              <Typography variant="h3" align="center" sx={{ mb: 4, mt: 3, fontWeight: 'normal' }}>
                Oops! You May Have Landed in Area 51 😉
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" align="center" sx={{ mt: 3 }}>
                It's looking like you may have taken a wrong turn. Don't worry... it happens to the best of us.
              </Typography>
              <Grid container justifyContent="center" sx={{ mb: 3, mt: 4 }}>
                <Button
                  variant="contained"
                  color="primary"
                  href="https://play.google.com/store/apps/details?id=com.garh.telemedicine&pcampaignid=web_share"
                  target="_blank"
                  fullWidth
                >
                  Go To Our Mobile App
                </Button>
              </Grid>
              <Grid container justifyContent="center" sx={{ mb: 3, mt: 4 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => logout({ logoutParams: { federated: true } })}
                  fullWidth
                >
                  Logout
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
