import { Availability } from '@web/data/models/Availability';
import { AppointmentPayload, NotificationEntity, SystemPayload, UserPayload } from '@web/gen/api';
import { NotificationItemProps } from '@web/components/atoms/displays/NotificationItem';
import { DisplayUtils } from '@web/utils/display.utils';
import { appointmentModalUrl, userModalUrl } from '@web/utils/url.utils';

export const defaultAvailabilitySlots = (): Availability[] => Array.from({ length: 5 })
  .flatMap((_, i) => [
    { day: i + 1, startTime: '07:00', endTime: '12:00' },
    { day: i + 1, startTime: '13:00', endTime: '18:00' }
  ]);


export const getNotificationItemProps = (item: Pick<NotificationEntity, 'type' | 'payload' | 'createdAt'>): NotificationItemProps => {
  let props: Omit<NotificationItemProps, 'timestamp'>;
  switch (item.type) {
    case 'SYSTEM':
      props = {
        icon: 'SettingOutlined',
        body: (item.payload as SystemPayload).body,
        subtext: (item.payload as SystemPayload).title,
        url: ''
      };
      break;
    case 'APPOINTMENT_DUE':
      props = {
        icon: 'ClockCircleOutlined',
        color: 'info',
        body: 'You have an appointment that is due',
        subtext: DisplayUtils.toTimeString((item.payload as AppointmentPayload).startsAt),
        url: appointmentModalUrl((item.payload as AppointmentPayload).appointmentId)
      };
      break;
    case 'APPOINTMENT_JOINED':
      props = {
        icon: 'PhoneOutlined',
        color: 'warning',
        body: 'The patient has joined the appointment',
        subtext: `Due ${DisplayUtils.toHumanDateFromString((item.payload as AppointmentPayload).startsAt)}`,
        url: appointmentModalUrl((item.payload as AppointmentPayload).appointmentId)
      };
      break;
    case 'APPOINTMENT_PAID':
      props = {
        icon: 'PhoneOutlined',
        color: 'success',
        body: 'A new appointment just arrived',
        subtext: `Due ${DisplayUtils.toHumanDateFromString((item.payload as AppointmentPayload).startsAt)}`,
        url: appointmentModalUrl((item.payload as AppointmentPayload).appointmentId)
      };
      break;
    case 'APPOINTMENT_CANCELLED':
      props = {
        icon: 'PhoneOutlined',
        color: 'error',
        body: 'An appointment has been cancelled',
        subtext: '.',
        url: appointmentModalUrl((item.payload as AppointmentPayload).appointmentId)
      };
      break;
    case 'APPOINTMENT_REASSIGNED':
      props = {
        icon: 'UserSwitchOutlined',
        color: 'info',
        body: 'An appointment has been assigned to you',
        subtext: DisplayUtils.toHumanDateFromString((item.payload as AppointmentPayload).startsAt),
        url: appointmentModalUrl((item.payload as AppointmentPayload).appointmentId)
      };
      break;
    case 'USER_INVITED':
      props = {
        icon: 'UserAddOutlined',
        color: 'info',
        body: 'You have been invited to join a facility',
        subtext: '.',
        url: userModalUrl((item.payload as UserPayload).userId)
      };
      break;
    case 'USER_ONBOARDED':
      props = {
        icon: 'UserAddOutlined',
        color: 'success',
        body: 'User onboarding is <b>100%</b> complete',
        subtext: '.',
        url: userModalUrl((item.payload as UserPayload).userId)
      };
      break;
    case 'USER_OFFBOARDED':
      props = {
        icon: 'UserDeleteOutlined',
        color: 'error',
        body: 'You have been removed from a facility',
        subtext: '.',
        url: userModalUrl((item.payload as UserPayload).userId)
      };
      break;
    case 'PAYMENT_DISBURSED':
      props = {
        icon: 'MoneyCollectOutlined',
        color: 'success',
        body: 'There\'s been a disbursement to your account',
        subtext: '.',
        url: '/finances/statements'
      };
      break;
  }
  return { ...props, timestamp: item.createdAt };
};
