import React from 'react';
import { Chip } from '@mui/material';
import { LegoProps } from '@ropha/builder';

const statusRegex: [RegExp, string][] = [
  [/^(pending|created)/i, 'info'],
  [/^(complete|success|approve|paid|submit)/i, 'success'],
  [/^(fail|error|offboard|reject)/i, 'error']
];

const statusColor = (status: string) => {
  return (statusRegex.find(([regex, _]) => regex.test(status)) || [null, 'secondary'])[1];
};

export const StatusChip = ({ label }: LegoProps['StatusChip']) => {
  const color = statusColor(label);
  return (
    <Chip
      label={label}
      size="small"
      color={color as any}
      variant="outlined"
      sx={{
        bgcolor: `${color}.lighter`,
        borderColor: `${color}.light`
      }}
    />
  );
};
