import React, { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import EnhancedDialog from '@web/components/molecules/EnhancedDialog';
import modals from '@web/components/modals';
import { MODAL_KEY } from '@web/utils/url.utils';

type OpenModal = (id: string, props?: Record<string, string>) => void
type CloseModal = () => void

const ModalContext = createContext<[OpenModal, CloseModal]>([] as any);

export const ModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [params, setURLSearchParams] = useSearchParams();

  const modal = useMemo(
    () => params.has(MODAL_KEY) ? modals[(params.get(MODAL_KEY) as keyof modals)!] : null,
    [params, modals]
  );

  const modalParams = useMemo(
    () => Object.fromEntries([...params.entries()].filter(([key]) => key !== MODAL_KEY)),
    [modal]
  );

  const open: OpenModal = (id: string, props = {}) => {
    setURLSearchParams({ ...props, [MODAL_KEY]: id });
  };

  const close: CloseModal = () => {
    setURLSearchParams([], { replace: true });
  };

  return (
    <ModalContext.Provider value={[open, close]}>
      {!!modal &&
        <EnhancedDialog {...modal.dialog} title={modal?.title} open onCancel={close}>
          <modal.component {...modalParams} />
        </EnhancedDialog>
      }
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = (modal: keyof modals) => {
  const [open, close] = useContext(ModalContext);
  return [(props?: Record<string, string>) => open(modal, props), close];
};
