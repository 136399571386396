import { Stack } from '@mui/material';
import { LegoProps } from '@ropha/builder';

import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/material/styles';

const blue = {
  200: '#b6daff',
  400: '#3399FF',
  600: '#0072E5'
};

const grey = {
  50: '#F3F6F9',
  200: '#DAE2ED',
  300: '#C7D0DD',
  700: '#434D5B',
  900: '#1C2025'
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      outline: 0;
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);


export function AreaInput({ label, value = '', onChange }: LegoProps['AreaInput']) {
  return (
    <Stack>
      <Textarea
        placeholder={label} value={value}
        minRows={3}
        onChange={e => onChange && onChange(e.target.value)}
      />
    </Stack>
  );
}
