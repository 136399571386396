// assets
import { GoldOutlined, HourglassOutlined, MedicineBoxOutlined, MessageOutlined } from '@ant-design/icons';

const menus = {
  id: 'group-activities',
  title: 'Activities',
  type: 'group',
  children: [
    {
      id: 'services',
      title: 'Service Offerings',
      type: 'item',
      url: '/activities/services',
      icon: GoldOutlined,
      breadcrumbs: true,
      fab: false,
      resource: 'services'
    },
    {
      id: 'specialities',
      title: 'Specialities',
      type: 'item',
      url: '/activities/specialities',
      icon: MedicineBoxOutlined,
      breadcrumbs: true,
      fab: false,
      resource: 'specialities'
    }
  ]
};

export default menus;
