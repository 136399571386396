import { CardMedia } from '@mui/material';
import React from 'react';

type ProfilePhotoProps = {
  src: string
  size?: number
}

export default function ProfilePhoto({ src, size = 112 }: ProfilePhotoProps) {
  return <CardMedia component="img" image={src} sx={{ width: size, height: size, borderRadius: '50%' }} />;
}
