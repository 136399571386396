import { Avatar, AvatarGroup, Button, Grid, Stack, Typography } from '@mui/material';
import avatar1 from '@web/assets/images/avatar-1.png';
import MainCard from '@web/components/atoms/cards/MainCard';

function SupportCard() {
  return (
    <MainCard>
      <Stack spacing={2}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Stack>
              <Typography variant="h5" noWrap>
                Help & Support Chat
              </Typography>
            </Stack>
          </Grid>
          <Grid item>
            <AvatarGroup sx={{ '& .MuiAvatar-root': { width: 32, height: 32 } }}>
              <Avatar alt="Remy Sharp" src={avatar1} />
            </AvatarGroup>
          </Grid>
        </Grid>
        <Button size="small" variant="contained" sx={{ textTransform: 'capitalize' }}
                href={'https://forms.gle/Hoe6ZsqUm8XEvkNb7'} target={'_blank'}>
          Need Help?
        </Button>
      </Stack>
    </MainCard>
  );
}

export default SupportCard;
