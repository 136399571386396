import { OfferingsViewModel } from '@web/store/OfferingsViewModel';
import { UserViewModel } from '@web/store/UserViewModel';
import { MenuViewModel } from '@web/store/MenuViewModel';
import { NotificationViewModel } from '@web/store/NotificationViewModel';

export class Mvvm {
  notification: NotificationViewModel;
  offering: OfferingsViewModel;
  user: UserViewModel;
  menu: MenuViewModel;

  constructor() {
    this.notification = new NotificationViewModel();
    this.offering = new OfferingsViewModel();
    this.user = new UserViewModel();
    this.menu = new MenuViewModel();
  }
}
