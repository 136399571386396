import { CardMedia, Divider, Grid, Stack } from '@mui/material';
import MainCard from '@web/components/atoms/cards/MainCard';
import { observer } from 'mobx-react-lite';
import Text from '@web/components/atoms/displays/Text';
import { useEffect, useState } from 'react';
import { api, useApi } from '@web/hooks/useApi';
import { AppointmentEntity, UserDetailsDto } from '@web/gen/api';
import LinearProgress from '@mui/material/LinearProgress';
import { DisplayUtils } from '@web/utils/display.utils';
import { WithLego } from '@web/components/organisms/WithLego';
import { LegoBuilder } from '@ropha/builder';
import { appointmentModalUrl } from '@web/utils/url.utils';

type UserDetailsProps = { id?: string }

function PatientDetails({ id }: UserDetailsProps) {
  const [details, setDetails] = useState<UserDetailsDto>();
  const [appointments, setAppointments] = useState<AppointmentEntity[]>([]);
  const [getUserDetails, isFetchingDetails] = useApi(api.users.details);
  const [getPatientAppointments, isFetchingAppointments] = useApi(api.appointments.patientAppointments);

  useEffect(() => {
    if (!id) return;
    getUserDetails(id).then(setDetails);
    getPatientAppointments(id).then(setAppointments);
  }, [id]);

  return (
        (isFetchingDetails || !details) ?
          <LinearProgress variant="indeterminate" /> :
          <MainCard sx={{ bgcolor: 'grey.50', m: 0, width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Stack alignItems="center" spacing={3}>
                  <Text variant="paragraph" content={'Patient Details'} />
                  <Divider sx={{ width: '100%' }} />
                  <CardMedia component="img" image={details.profilePicture}
                             sx={{ width: 112, borderRadius: '50%' }} />
                  <Stack alignItems="start" spacing={1}>
                    <Text variant="paragraph-bold" content={DisplayUtils.fullName(details)} />
                    <Text variant="text" content={details.email} />
                  </Stack>
                  <Stack direction="column" spacing={2} width="100%" padding={3} justifyContent="space-around">
                    <LeftInfoTab name={'Gender'} value={details.gender} />
                    <Divider />
                    <LeftInfoTab name={'Onboarded On'} value={DisplayUtils.toDateString(details.createdAt)} />
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={7}>
                <Stack alignItems="center" spacing={2}>
                  <Text variant="paragraph" content={'Appointments'} />
                  <Divider sx={{ width: '100%' }} />
                  <WithLego lego={
                      LegoBuilder.ListCard()
                        .items(
                          appointments.map(appointment => ({
                            primaryLabel: appointment.narrative,
                            altLabel: `${appointment.mode} ・・・ ${appointment.status}`,
                            primaryValue: DisplayUtils.toDateString(appointment.startsAt),
                            altValue: DisplayUtils.toTimeString(appointment.startsAt),
                            url: `/activities/appointments${appointmentModalUrl(appointment.id)}`
                          }))
                        )
                        .build()
                    }
                  />
                </Stack>
              </Grid>
            </Grid>
          </MainCard>
  );
}

function LeftInfoTab({ name, value, align = 'center' }: { name: string; value: string; align?: string }) {
  return (
    <Grid item>
      <Text variant="text" content={name} />
      <Text variant="paragraph-bold" content={value} />
    </Grid>
  );
}

export default observer(PatientDetails);
