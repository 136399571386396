import { AppointmentDetailEntity } from '@web/gen/api';
import { Button } from '@mui/material';
import { AntdIcon } from '@web/components/atoms/AntdIcon';

type JoinButtonProps = {
  mode: AppointmentDetailEntity['mode'],
  status: AppointmentDetailEntity['status'],
  id: string
};

export function JoinButton({ mode, id, status }: JoinButtonProps) {
  if (status === 'COMPLETED')
    return <Button variant="outlined" size="large"
                   color="info"
                   fullWidth
                   href={`/activities/appointment/${id}`}
                   target="_blank"
                   startIcon={<AntdIcon name={'FormOutlined'} />}
    >
      Revisit Appointment
    </Button>;
  if (!['SCHEDULED', 'STARTED'].includes(status)) return null;
  switch (mode) {
    case 'TEXT':
      return <Button variant="outlined" size="large"
                     color="warning"
                     fullWidth
                     href={`/activities/appointment/${id}`}
                     target="_blank"
                     startIcon={<AntdIcon name={'WechatOutlined'} />}
      >
        Join Chat
      </Button>;
    case 'AUDIO':
      return <Button variant="contained" size="large"
                     color="error"
                     fullWidth
                     href={`/activities/appointment/${id}`}
                     target="_blank"
                     startIcon={<AntdIcon name={'PhoneOutlined'} flip />}
      >
        Join Audio Call
      </Button>;
    case 'VIDEO':
      return <Button variant="contained" size="large"
                     color="info"
                     fullWidth
                     href={`/activities/appointment/${id}`}
                     target="_blank"
                     startIcon={<AntdIcon name={'VideoCameraAddOutlined'} />}
      >
        Join Video Call
      </Button>;
  }
}
