import { Grid, GridProps } from '@mui/material';
import { Lego } from '@ropha/builder';
import { useLego } from '@web/hooks/useLego';
import { Fragment } from 'react';

export type WithLegoProps = {
  grid?: Pick<GridProps, 'xs' | 'sm' | 'md' | 'lg' | 'xl'>;
  lego: Lego,
}

export function WithLego({ lego, grid }: WithLegoProps) {
  const item = useLego(lego).map((i, index) => <Fragment key={index}>{i}</Fragment>);
  return grid ? <Grid item {...grid}>{item}</Grid> : item;
}
