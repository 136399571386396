import AppointmentSummary from '@web/components/modals/AppointmentSummary';
import UserDetails from '@web/components/modals/UserDetails';
import { FC } from 'react';
import { EnhancedDialogProps } from '@web/components/molecules/EnhancedDialog';
import AvailabilitySettings from '@web/components/modals/AvailabilitySettings';
import UserDeleteConfirmation from '@web/components/modals/UserDeleteConfirmation';
import PatientDetails from '@web/components/modals/PatientDetails';
import DisbursementDetails from '@web/components/modals/DisbursementDetails';

type modals = Record<
  'appointment' | 'user' | 'patient' | 'availability' | 'delete-user' | 'disbursement',
  {
    title?: string;
    component: FC;
    dialog?: Pick<EnhancedDialogProps, 'size'>
  }
>

const modals: modals = {
  appointment: { title: 'View Appointment', component: AppointmentSummary },
  user: { component: UserDetails, dialog: { size: 'xs' } },
  patient: { component: PatientDetails, dialog: { size: 'sm' } },
  availability: { title: 'Availability Settings', component: AvailabilitySettings },
  'delete-user': {
    title: 'Confirm Deletion',
    component: UserDeleteConfirmation,
    dialog: { size: 'sm' }
  },
  disbursement: {title: 'Disbursement', component: DisbursementDetails, dialog: { size: 'sm' }}
};

export default modals;
