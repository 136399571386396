import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { DoctorListItemDto } from '@web/gen/api';
import { api, useApi } from '@web/hooks/useApi';
import { Grid, Stack } from '@mui/material';
import { EnhancedForms } from '@web/components/molecules/EnhancedForms';
import { LegoBuilder } from '@ropha/builder';
import { USER_DROPDOWN } from '@web/utils/dropdown.renderers';
import { DisplayUtils } from '@web/utils/display.utils';
import { LoadingButton } from '@mui/lab';
import { CheckOutlined } from '@ant-design/icons';
import LinearProgress from '@mui/material/LinearProgress';

export const AssignDoctorForm = observer(({ id, refresh }: { id: string; refresh: () => void }) => {
  const [doctor, setDoctor] = useState<DoctorListItemDto>();
  const [availableDoctors, setAvailableDoctors] = useState<DoctorListItemDto[]>([]);
  const [saving, setSaving] = useState(false);
  const [listDoctors, listingDoctors] = useApi(api.users.listDoctors);
  const [reassignAppointment] = useApi(api.appointments.reassign);

  const searchDoctors = useCallback(async (input: string) => {
    if (!input.length) return availableDoctors;
    return availableDoctors.filter(doctor =>
      doctor.firstName.toLowerCase().includes(input.toLowerCase()) ||
      doctor.lastName.toLowerCase().includes(input.toLowerCase()) ||
      (doctor.otherNames && doctor.otherNames.toLowerCase().includes(input.toLowerCase())) ||
      doctor.email.toLowerCase().includes(input.toLowerCase())
    );
  }, [availableDoctors]);

  function onSubmit() {
    if (!doctor) return;
    setSaving(true);
    reassignAppointment(id, { doctorId: doctor.id })
      .then(refresh)
      .finally(() => setSaving(false));
  }

  useEffect(() => {
    listDoctors().then(setAvailableDoctors);
  }, []);

  return (
    <Grid item xs={12} sx={{ width: '100%', pl: 2 }}>
      {listingDoctors ?
        <LinearProgress variant="indeterminate" /> :
        <>
          <EnhancedForms
            forms={
              LegoBuilder.InputGroup().grid({ xs: 12 })
                .inputs({
                  doctor: LegoBuilder.SelectInput()
                    .label('Doctor').renderer(USER_DROPDOWN)
                    .getOptions(searchDoctors)
                    .getLabel(DisplayUtils.fullName)
                    .build()
                })
                .build()
            }
            value={{ doctor }}
            onChange={({ doctor }) => setDoctor(doctor)}
          />
          <Stack direction="row" spacing={3} mt={3}>
            <LoadingButton
              loading={saving}
              onClick={onSubmit}
              variant="contained"
              color="success"
              disableElevation sx={{ borderRadius: 0 }}
              endIcon={<CheckOutlined />}
              disabled={!doctor}
            >
              Submit
            </LoadingButton>
          </Stack>
        </>
      }
    </Grid>
  );
});
