import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { LegoProps } from '@ropha/builder';
import { CardContent, Divider, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { CloudUploadOutlined } from '@ant-design/icons';
import Text from '@web/components/atoms/displays/Text';

export function FileInput({ label, value, allowOverwrite, onChange }: LegoProps['FileInput']) {
  const onDrop = useCallback(([file]: File[]) => onChange && onChange(file), []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1 });

  return (
    <Paper elevation={0} variant="outlined">
      {(!value || allowOverwrite) &&
        <CardContent sx={{ border: '2px dashed #e0e0e0', cursor: 'pointer' }}  {...getRootProps()}>
          <input {...getInputProps()} />
          <Grid container spacing={1} direction="column" alignItems="center">
            <Grid item>
              <CloudUploadOutlined style={{ fontSize: 22, color: 'grey' }} />
            </Grid>
            <Grid item>
              <Typography variant="h4" color="grey">{label}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" color="grey">Drop files here or click to upload!</Typography>
            </Grid>
          </Grid>
        </CardContent>
      }
      {value &&
        <>
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item>
                    <Text variant="text" content={value.name} />
                  </Grid>
                  <Grid item>
                    <Text variant="text" content="100%" />
                  </Grid>
                  <Grid item xs={12} className="css-1osx3qj">
                    <LinearProgress variant="determinate" value={100} color="primary" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </>
      }
    </Paper>
  );
}
