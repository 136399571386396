import { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';
import { useApi } from '@web/hooks/useApi';
import { Mvvm } from '@web/store/Mvvm';

const MvvmContext = createContext<Mvvm>({} as Mvvm);

export const MvvmProvider: FC<PropsWithChildren> = ({ children }) => {

  useApi(async () => void 0);

  const mvvm = useMemo(() => new Mvvm(), []);

  return (
    <MvvmContext.Provider value={mvvm}>
      {children}
    </MvvmContext.Provider>
  );
};

export const useMvvm = () => useContext(MvvmContext);
