import { Box, Table, TableContainer } from '@mui/material';

export default function EnhancedTable({ maxHeight = '100%', children }: { maxHeight?: number|string; children: React.ReactNode }) {

  return (
    <Box sx={{ overflow: 'hidden', maxWidth: '100%' }}>
      <TableContainer
        sx={{
          width: '100%',
          maxWidth: '100%',
          maxHeight: maxHeight,
          overflowX: 'auto',
          position: 'relative',
          display: 'block',
          '& td, & th': { whiteSpace: 'nowrap' }
        }}
      >
        <Table
          stickyHeader
          aria-labelledby="tableTitle"
          sx={{
            '& .MuiTableCell-root:first-of-type': {
              pl: 2
            },
            '& .MuiTableCell-root:last-of-type': {
              pr: 3
            },
            '& .MuiTableCell-head': {
              py: 1.5,
              textTransform: 'uppercase',
              fontSize: 12,
              '&::after': {
                position: 'absolute',
                content: '""',
                backgroundColor: 'rgb(211,211,211)',
                width: '1px',
                height: 'calc(100% - 30px)',
                right: 0,
                top: 16
              }
            }
          }}
        >
          {children}
        </Table>
      </TableContainer>
    </Box>
  );
}
