import { Avatar, ListItem as MuiListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { LegoProps } from '@ropha/builder';
import { AntdIcon } from '@web/components/atoms/AntdIcon';
import React from 'react';

export default function ListItem({ icon, avatar, primaryText, secondaryText }: LegoProps['ListItem']) {
  return (
    <MuiListItem>
      {avatar &&
        <ListItemAvatar>
          <Avatar src={avatar} />
        </ListItemAvatar>
      }
      {icon &&
        <ListItemAvatar>
          <Avatar>
            <AntdIcon name={icon} />
          </Avatar>
        </ListItemAvatar>
      }
      <ListItemText primary={primaryText} secondary={secondaryText} />
    </MuiListItem>
  );
}
