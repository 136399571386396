import { LegoProps } from '@ropha/builder';
import { Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

const variantMaps: Record<string, Variant> = {
  'heading': 'h4',
  'paragraph': 'body1',
  'paragraph-bold': 'subtitle1',
  'text': 'body2',
  'text-bold': 'subtitle2',
  'caption': 'caption'
};

export default function Text(props: LegoProps['Text']) {
  return (
    <Typography variant={variantMaps[props.variant]}>
      {props.content}
    </Typography>
  );
}
