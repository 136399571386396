import * as React from 'react';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { AntdIcon } from '@web/components/atoms/AntdIcon';
import { api, useApi } from '@web/hooks/useApi';
import LinearProgress from '@mui/material/LinearProgress';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&::before': {
    display: 'none'
  }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<AntdIcon name={'ArrowsAltOutlined'} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

interface DoctorReportsProps {
  patientId: string;
}

export default function DoctorReports({ patientId }: DoctorReportsProps) {
  const [reports, setReports] = React.useState<string[]>([]);
  const [getPastReports, loading] = useApi(api.reports.pastNotes);

  useEffect(() => {
    getPastReports(patientId).then(setReports);
  }, []);

  return (
    loading ?
      <LinearProgress variant="indeterminate" /> :
      <div>
        {
          reports.map((report, index) => (
              <Accordion expanded key={index}>
                <AccordionSummary>
                  <Typography></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{report}</Typography>
                </AccordionDetails>
              </Accordion>
            )
          )
        }
      </div>
  );
}
