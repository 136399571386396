import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { AntdIcon } from '@web/components/atoms/AntdIcon';
import React from 'react';
import { DisplayUtils } from '@web/utils/display.utils';

export type NotificationItemProps = {
  body: React.ReactNode;
  timestamp: string
  color?: 'success' | 'warning' | 'error' | 'info'
  subtext: string;
  icon?: string
  avatar?: string
  url: string
}

export default function NotificationItem(
  { color = 'info', icon, avatar, body, timestamp, subtext, url }: NotificationItemProps
) {
  return (
    <ListItemButton divider component={RouterLink} to={url}>
      {avatar &&
        <ListItemAvatar>
          <Avatar sx={{ color: `${color}.main`, bgcolor: `${color}.lighter` }} src={avatar} />
        </ListItemAvatar>
      }
      {icon &&
        <ListItemAvatar>
          <Avatar sx={{ color: `${color}.main`, bgcolor: `${color}.lighter` }}>
            <AntdIcon name={icon} />
          </Avatar>
        </ListItemAvatar>
      }
      <ListItemText
        primary={
          <Typography variant="body1">{body}</Typography>
        }
        secondary={subtext}
      />
      <ListItemSecondaryAction>
        <Typography variant="caption" noWrap>
          {DisplayUtils.toNotification(timestamp)}
        </Typography>
      </ListItemSecondaryAction>
    </ListItemButton>
  );
}
