import { observer } from 'mobx-react-lite';
import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import MainCard from '@web/components/atoms/cards/MainCard';
import { api, useApi } from '@web/hooks/useApi';
import LinearProgress from '@mui/material/LinearProgress';
import { useEffect, useRef, useState } from 'react';
import { PaymentDetailsDto } from '@web/gen/api';
import { LegoBuilder } from '@ropha/builder';
import { INVOICE_TABLE_RENDERER } from '@web/utils/table.renderers';
import { WithLego } from '@web/components/organisms/WithLego';
import { DisplayUtils } from '@web/utils/display.utils';
import { useMvvm } from '@web/contexts/MvvmContext';
import { useReactToPrint } from 'react-to-print';
import { useTenantTheme } from '@web/contexts/TenantThemeContext';

type DisbursementDetailsProps = { id?: string }

function DisbursementDetails({ id }: DisbursementDetailsProps) {
  const [details, setDetails] = useState<PaymentDetailsDto>();
  const [fetch, isLoading, error] = useApi(api.payments.details);
  const [tenant] = useTenantTheme();
  const mvvm = useMvvm();
  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({ content: () => componentRef.current! });

  useEffect(() => {
    if (!id) return;
    fetch(id).then(setDetails);
  }, []);

  return (
    (isLoading || !details) ?
      <LinearProgress variant="indeterminate" /> :
      <MainCard sx={{ bgcolor: 'grey.50', m: 0, width: '100%' }} ref={componentRef}>
        <Grid container display={'flex'} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6}>
            <img src={tenant.logoBanner} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h4" component="h1" style={{ textAlign: 'right' }}>
              Invoice
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              Date: {DisplayUtils.toDateString(details.createdAt)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
            <Typography variant="h6">
              Ropha Telemedicine
            </Typography>
            <Typography variant="body1">
              1234 Company Address<br />
            </Typography>
          </Grid>
        </Grid>

        {(mvvm.user.hasTenant || mvvm.user.user) &&
          <Grid container spacing={2} style={{ marginTop: '20px', marginBottom: '20px' }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">
                Bill To:
              </Typography>
              {mvvm.user.hasTenant ?
                <Typography variant="body1">
                  {tenant.title}<br />
                </Typography>
                :
                <Typography variant="body1">
                  {DisplayUtils.fullName(mvvm.user.user!.profile!)} <br />
                  {mvvm.user.user?.profile?.address || ''} <br />
                  {mvvm.user.user?.profile?.digitalAddress || ''} <br />
                </Typography>
              }
            </Grid>
          </Grid>
        }

        <WithLego
          grid={{ xs: 12 }}
          lego={
            LegoBuilder.DataTable()
              .cells(['Reference', 'Date', 'Amount'])
              .records(details.subPayments || [])
              .maxHeight('calc(100vh - 150px)')
              .renderer(INVOICE_TABLE_RENDERER)
              .build()
          }
        />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}></Grid>
          <Grid item xs={12} sm={6}>
            <TableContainer style={{ marginTop: '20px' }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">Subtotal</TableCell>
                    <TableCell align="right">{DisplayUtils.toMoney(details.amount)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">Tax</TableCell>
                    <TableCell align="right">{DisplayUtils.toMoney(0.00)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">Total</TableCell>
                    <TableCell align="right">{DisplayUtils.toMoney(details.amount)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: '20px' }}>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <Button className="hidden-print" variant="contained" color="primary" onClick={handlePrint}>
              Print
            </Button>
          </Grid>
        </Grid>
      </MainCard>
  );
}

export default observer(DisbursementDetails);
