import { LegoBuilder } from '@ropha/builder';

export const BasicFacilityDetailsForm = LegoBuilder.InputGroup()
  .inputs({
    name: LegoBuilder.TextInput().label('Facility Name').build(),
    email: LegoBuilder.TextInput().grid({ xs: 12, sm: 6 })
      .label('Email Address').build(),
    phoneNumber: LegoBuilder.TextInput().grid({ xs: 12, sm: 6 })
      .label('Phone Number').build(),
    address: LegoBuilder.TextInput().grid({ xs: 12, sm: 8 })
      .label('Address / Location').build(),
    digitalAddress: LegoBuilder.TextInput().grid({ xs: 12, sm: 4 })
      .label('Digital Address').build(),
    themeColor: LegoBuilder.TextInput().grid({ xs: 4 })
      .label('Theme Color').build(),
    slug: LegoBuilder.TextInput().grid({ xs: 8 })
      .label('Facility Slug/Domain').build(),
    isLicensed: LegoBuilder.ToggleInput()
      .label('I confirm that the facility is licensed to operate').build()
  })
  .build()
