import React from 'react';
import { IconButton } from '@mui/material';
import { AntdIcon } from '@web/components/atoms/AntdIcon';

type ActionButtonProps = {
  icon: string;
  color: string;
  onClick?: () => void;
}

const ActionButton = ({ icon, color, onClick }: ActionButtonProps) => {
  return (
    <IconButton color={color as any} size="large" onClick={onClick}>
      <AntdIcon name={icon} />
    </IconButton>
  );
};

export default ActionButton;
