export const MODAL_KEY = 'modal';

export const modalUrl = (id: string, props: Record<string, string>) =>
  `?${new URLSearchParams({ ...props, [MODAL_KEY]: id }).toString()}`;

export const appointmentModalUrl = (appointmentId: string) =>
  modalUrl('appointment', { id: appointmentId });

export const userModalUrl = (userId: string) =>
  modalUrl('user', { id: userId });
