import { LegoBuilder } from '@ropha/builder';

export const BasicDoctorDetailsForm = LegoBuilder.InputGroup()
  .inputs({
    firstName: LegoBuilder.TextInput().grid({xs: 12, sm: 6})
      .label('First Name').build(),
    lastName: LegoBuilder.TextInput().grid({xs: 12, sm: 6})
      .label('Last Name').build(),
    email: LegoBuilder.TextInput().grid({ xs: 12, sm: 6 })
      .label('Email Address').build(),
    phoneNumber: LegoBuilder.TextInput().grid({ xs: 12, sm: 6 })
      .label('Phone Number').build(),
    address: LegoBuilder.TextInput().grid({ xs: 12, sm: 8 })
      .label('Address / Location').build(),
    digitalAddress: LegoBuilder.TextInput().grid({ xs: 12, sm: 4 })
      .label('Digital Address').build()
  })
  .build();
