import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { LockOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { AntdIcon } from '@web/components/atoms/AntdIcon';
import { useMvvm } from '@web/contexts/MvvmContext';
import { useModal } from '@web/contexts/ModalContext';

const SettingTab = () => {
  const theme = useTheme();
  const mvvm = useMvvm();
  const [openAvailabilities] = useModal('availability');

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const handleListItemClick = (event: any, index: number) => {
    setSelectedIndex(index);
  };

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}>
      {mvvm.user.hasOwnedPermission('create:availabilities') &&
        <ListItemButton onClick={() => openAvailabilities()}>
          <ListItemIcon>
            <AntdIcon name={'HourglassOutlined'} />
          </ListItemIcon>
          <ListItemText primary="Availability" />
        </ListItemButton>
      }
      <ListItemButton href={'https://forms.gle/Hoe6ZsqUm8XEvkNb7'} target={'_blank'}>
        <ListItemIcon>
          <QuestionCircleOutlined />
        </ListItemIcon>
        <ListItemText primary="Support" />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 2} onClick={(event) => handleListItemClick(event, 2)}>
        <ListItemIcon>
          <LockOutlined />
        </ListItemIcon>
        <ListItemText primary="Privacy Center" />
      </ListItemButton>
    </List>
  );
};

export default SettingTab;
