// assets
import { BankOutlined, HddOutlined } from '@ant-design/icons';

const menus = {
  id: 'group-onboarding',
  title: 'Onboarding',
  type: 'group',
  children: [
    {
      id: 'facilities',
      title: 'Facilities',
      type: 'item',
      url: '/onboarding/facilities',
      icon: BankOutlined,
      breadcrumbs: true,
      fab: '/wizards/facility',
      resource: 'facilities',
    },
    {
      id: 'insurers',
      title: 'Insurers',
      type: 'item',
      url: '/onboarding/insurers',
      icon: HddOutlined,
      breadcrumbs: true,
      fab: '/wizards/insurers',
      resource: 'insurers',
    }
  ]
};

export default menus;
