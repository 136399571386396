import { Lego } from '@ropha/builder';
import { Components } from '@web/components/lego';
import React from 'react';

export class RenderComponentVisitor {
  visit({ name, ...props }: Lego): React.ReactNode {
    let children;
    if (props.hasOwnProperty('children'))
      //@ts-ignore
      children = this.visitChildren(props.children);
    return this.render(name, props, children);
  }

  visitChildren(children: Lego[]): React.ReactNode[] {
    return children.map(c => this.visit(c));
  }

  private render(name: string, props: any, children: any): React.ReactNode {
    if (Components.hasOwnProperty(name))
      return React.createElement((Components as any)[name], props, children);
    else {
      console.warn(`Component '${name}' not found.`);
      return null;
    }
  }
}

const visitor = new RenderComponentVisitor();

export const useLego = (...legos: Lego[]): React.ReactNode[] => {
  return visitor.visitChildren(legos);
};
