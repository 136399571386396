import { CardMedia, Divider, Grid, Stack } from '@mui/material';
import MainCard from '@web/components/atoms/cards/MainCard';
import { observer } from 'mobx-react-lite';
import Text from '@web/components/atoms/displays/Text';
import { useEffect, useState } from 'react';
import { api, useApi } from '@web/hooks/useApi';
import { UserDetailsDto } from '@web/gen/api';
import LinearProgress from '@mui/material/LinearProgress';
import { DisplayUtils } from '@web/utils/display.utils';

type UserDetailsProps = { id?: string }

function UserDetails({ id }: UserDetailsProps) {
  const [details, setDetails] = useState<UserDetailsDto>();
  const [getUserDetails, isFetchingDetails] = useApi(api.users.details);

  useEffect(() => {
    if (!id) return;
    getUserDetails(id).then(setDetails);
  }, [id]);

  return (
    (isFetchingDetails || !details) ?
      <LinearProgress variant="indeterminate" /> :
      <MainCard sx={{ bgcolor: 'grey.50', m: 0, width: '100%' }}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Stack alignItems="center" spacing={3}>
              <Text variant="paragraph" content={`${DisplayUtils.enumToHuman(details.type)} Details`} />
              <Divider sx={{ width: '100%' }} />
              <CardMedia component="img" image={details.profilePicture}
                         sx={{ width: 112, borderRadius: '50%' }} />
              <Stack alignItems="start" spacing={1}>
                <Text variant="paragraph-bold" content={DisplayUtils.fullName(details)} />
                <Text variant="text" content={details.email} />
              </Stack>
              <Stack direction="column" spacing={2} width="100%" padding={3} justifyContent="space-around">
                <LeftInfoTab name={'Gender'} value={details.gender} />
                <Divider />
                <LeftInfoTab name={'Onboarded On'} value={DisplayUtils.toDateString(details.createdAt)} />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </MainCard>
  );
}

function LeftInfoTab({ name, value, align = 'center' }: { name: string; value: string; align?: string }) {
  return (
    <Grid item>
      <Text variant="text" content={name} />
      <Text variant="paragraph-bold" content={value} />
    </Grid>
  );
}

export default observer(UserDetails);
