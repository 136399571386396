import { TableCell, TableHead, TableRow } from '@mui/material';

export function EnhancedTableHead({ cells }: { cells: string[] }) {
  return (
    <TableHead>
      <TableRow>
        {cells.map((cell, index) => (
          <TableCell key={index}>{cell}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
