import { Avatar, CardMedia, Divider, Grid, ListItemAvatar, Stack } from '@mui/material';
import MainCard from '@web/components/atoms/cards/MainCard';
import { observer } from 'mobx-react-lite';
import Text from '@web/components/atoms/displays/Text';
import DoctorReports from '@web/components/modals/AppointmentSummary/DoctorReports';
import React, { useEffect, useState } from 'react';
import { api, useApi } from '@web/hooks/useApi';
import { AppointmentDetailEntity } from '@web/gen/api';
import LinearProgress from '@mui/material/LinearProgress';
import { DisplayUtils } from '@web/utils/display.utils';
import { useMvvm } from '@web/contexts/MvvmContext';
import { LeftInfoTab } from '@web/components/modals/AppointmentSummary/LeftInfoTab';
import { JoinButton } from '@web/components/modals/AppointmentSummary/JoinButton';
import { AssignDoctorForm } from '@web/components/modals/AppointmentSummary/AssignDoctorForm';
import ProfilePhoto from '@web/components/atoms/ProfilePhoto';
import LabResults from '@web/components/modals/AppointmentSummary/LabResults';

type AppointmentSummaryProps = { id?: string }

function AppointmentSummary({ id }: AppointmentSummaryProps) {
  const [details, setDetails] = useState<AppointmentDetailEntity>();
  const [getAppointmentDetails, isFetchingDetails] = useApi(api.appointments.details);

  const mvvm = useMvvm();

  useEffect(loadResources, [id]);

  function loadResources() {
    if (!id) return;
    getAppointmentDetails(id).then(setDetails);
  }

  return (
    (isFetchingDetails || !details) ?
      <LinearProgress variant="indeterminate" /> :
      <MainCard sx={{ bgcolor: 'grey.50', m: 0, width: '100%' }}>
        <Grid container>
          <Grid container item xs={8}>
            <Grid item xs={12}>
              <Stack alignItems="center" spacing={3}>
                <Text variant="paragraph" content={'Booking Details'} />
                <Divider sx={{ width: '100%' }} />
              </Stack>
            </Grid>
            <Grid item xs={6} sx={{ borderRight: '1px solid #E0E0E0' }}>
              <Stack alignItems="center" spacing={3} sx={{mt: 1}}>
                <ProfilePhoto src={details.patient.profilePicture} />
                <Stack alignItems="center" spacing={1}>
                  <Text variant="paragraph-bold" content={DisplayUtils.fullName(details.patient)} />
                  <Text variant="text" content={details.patient.email} />
                </Stack>
                <Stack direction="row" spacing={2} width="100%" padding={3} justifyContent="space-around">
                  <LeftInfoTab name={'Service'} value={'General Consultation'} />
                  <Divider orientation="vertical" flexItem />
                  <LeftInfoTab name={'Duration'}
                               value={DisplayUtils.durationBetween(details.startsAt, details.completesAt)} />
                </Stack>
                {mvvm.user.assumesRole('DOCTOR') &&
                  <Stack direction="row" width="100%" sx={{ px: 3 }}>
                    <JoinButton mode={details.mode} status={details.status} id={details.id} />
                  </Stack>
                }
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack justifyContent="space-between" height={'100%'} sx={{ px: 4, py: 4 }} spacing={2.5}>
                <LeftInfoTab name={'Symptoms'} value={details.symptoms.join(', ')} />
                <LeftInfoTab name={'Status'} value={details.status.toString()} />
                <LeftInfoTab name={'Narrative'} value={details.narrative} />
                <LeftInfoTab name={'Payment Amount'} value={DisplayUtils.toMoney(details.cost)} />
              </Stack>
            </Grid>
          </Grid>
          {mvvm.user.assumesRole('FACILITY_MANAGER') &&
            <Grid item xs={4} sx={{ borderLeft: '1px solid #E0E0E0' }}>
              {!!details.doctor &&
                <Stack alignItems="center" spacing={3} sx={{ mb: 3 }}>
                  <Text variant="paragraph" content={'Assigned Doctor'} />
                  <Divider sx={{ width: '100%' }} />
                  <ProfilePhoto src={details.doctor.profilePicture} />
                  <Stack alignItems="center" spacing={1}>
                    <Text variant="paragraph-bold" content={DisplayUtils.fullName(details.doctor)} />
                    <Text variant="text" content={details.doctor.email} />
                  </Stack>
                </Stack>
              }
              {['PENDING_ASSIGNMENT', 'SCHEDULED'].includes(details.status) &&
                <Stack alignItems="center" spacing={3}>
                  <Divider sx={{ width: '100%' }} />
                  <Text variant="paragraph" content={details.doctor ? 'Reassign Doctor' : 'Assign a Doctor'} />
                  <Divider sx={{ width: '100%' }} />
                  <AssignDoctorForm id={details.id} refresh={loadResources} />
                </Stack>
              }
            </Grid>
          }
          {mvvm.user.assumesRole('DOCTOR') &&
            <Grid item xs={4} sx={{ borderLeft: '1px solid #E0E0E0' }}>
              {details.report?.labRequests &&
                <LabResults labs={details.report?.labRequests} />
              }
              <Stack spacing={1.5} ml={2}>
                <Text variant="paragraph-bold" content="Past Doctors' Notes"></Text>
                <DoctorReports patientId={details.patient.id} />
              </Stack>
            </Grid>
          }
        </Grid>
      </MainCard>
  );
}

export default observer(AppointmentSummary);
