import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { Grid, Typography } from '@mui/material';

import MainCard from '@web/components/atoms/cards/MainCard';

type BreadcrumbsProps = {
  items: string[]
}

const Breadcrumbs = ({ items }: BreadcrumbsProps) => {
  const crumbs = [...items];
  const active = crumbs.pop();

  return (
    <MainCard border={false} sx={{ mb: 3, bgcolor: 'transparent' }} content={false}>
      <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
        <Grid item>
          <MuiBreadcrumbs aria-label="breadcrumb">
            {crumbs.map((crumb, index) =>
              <Typography color="textSecondary" variant="h6" key={index}>{crumb}</Typography>
            )}
            <Typography variant="subtitle1" color="textPrimary">
              {active}
            </Typography>
          </MuiBreadcrumbs>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default Breadcrumbs;
