import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { LegoProps } from '@ropha/builder';

export function ToggleInput({ label, value = false, onChange }: LegoProps['ToggleInput']) {
  return (
    <Stack>
      <FormControlLabel
        control={<Checkbox color="primary" size="medium" />}
        label={label}
        onChange={(_, checked) => onChange(checked)}
        checked={value}
      />
    </Stack>
  );
}
