import { Grid } from '@mui/material';
import Text from '@web/components/atoms/displays/Text';

export function LeftInfoTab({ name, value, align = 'center' }: { name: string; value: string; align?: string }) {
  return (
    <Grid item>
      <Text variant="text" content={name} />
      <Text variant="paragraph-bold" content={value} />
    </Grid>
  );
}
