import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';
import NavBreadcrumbs from '@web/components/molecules/NavBreadcrumbs';

import WizardsSpeedDial from '@web/components/atoms/WizardsSpeedDial';
import Header from '@web/components/molecules/Header';
import Drawer from '@web/components/molecules/Drawer';
import { useMvvm } from '@web/contexts/MvvmContext';
import { observer } from 'mobx-react-lite';
import { withUserLoading } from '@web/hocs/withUserLoading';
import { call } from '@web/utils/api.utils';
import { api } from '@web/hooks/useApi';
import { useAuth0 } from '@auth0/auth0-react';
import LinearProgress from '@mui/material/LinearProgress';
import { withNotifications } from '@web/hocs/withNotifications';

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const { user } = useAuth0();
  const mvvm = useMvvm();
  const navigate = useNavigate();
  const [checkingOnboarding, setCheckingOnboarding] = useState(true);

  const handleDrawerToggle = () => {
    mvvm.menu.openDrawer(!mvvm.menu.drawerOpen);
  };

  useEffect(() => {
    mvvm.menu.openDrawer(!matchDownLG);
  }, [matchDownLG]);

  useEffect(() => {
    if (mvvm.user.assumesRole('UNKNOWN')) {
      navigate('/wizards/onboarding');
    } else if (mvvm.user.assumesRole('DOCTOR') && !mvvm.user.user?.onboarded) {
      navigate('/wizards/doctor');
    } else if (user && !mvvm.user.user?.onboarded) {
      switch (mvvm.user.userType) {
        case 'FACILITY_MANAGER':
          call(api.users.onboardFacilityManager, {
            title: '',
            profilePicture: user.profilePicture!,
            firstName: user.given_name!,
            lastName: user.family_name!,
            primaryContact: {
              email: user.email!,
              contactNumber: ''
            }
          }).then(() => window.location.reload());
          break;
        case 'INSURANCE_MANAGER':
          call(api.users.onboardInsuranceManager, {
            title: '',
            profilePicture: user.profilePicture,
            firstName: user.given_name!,
            lastName: user.family_name!,
            primaryContact: {
              email: user.email!,
              contactNumber: ''
            }
          }).then(() => window.location.reload());
          break;
        case 'MEDICAL_DIRECTOR':
          call(api.users.onboardMedicalDirector, {
            title: '',
            profilePicture: user.profilePicture!,
            firstName: user.given_name!,
            lastName: user.family_name!,
            primaryContact: {
              email: user.email!,
              contactNumber: ''
            }
          }).then(() => window.location.reload());
          break;
        default:
          setCheckingOnboarding(false);
      }
    } else {
      setCheckingOnboarding(false);
    }
  }, []);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      {checkingOnboarding ?
        <LinearProgress variant="indeterminate" /> :
        <>
          <Header showSearch={false} open={mvvm.menu.drawerOpen} handleDrawerToggle={handleDrawerToggle} />
          <Drawer open={mvvm.menu.drawerOpen} handleDrawerToggle={handleDrawerToggle} />
          <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 }, paddingTop: '85px !important' }}>
            <NavBreadcrumbs navigation={mvvm.user.navigation} />
            <Outlet />
          </Box>
          <WizardsSpeedDial menus={mvvm.user.speedDials} />
        </>
      }
    </Box>
  );
};

export default observer(withUserLoading(withNotifications(MainLayout)));
