import { action, makeObservable, observable } from 'mobx';
import { NotificationEntity, OfferingEntity } from '@web/gen/api';
import { api } from '@web/hooks/useApi';
import { call } from '@web/utils/api.utils';

export class NotificationViewModel {

  @observable items: NotificationEntity[] = [];
  @observable specialities: OfferingEntity[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  async load() {
    this.items = await call(api.notifications.list);
  }

  get unreadCount() {
    return this.items.filter(item => item.readAt === null).length;
  }

}
