import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

import { observer } from 'mobx-react-lite';
import { useMvvm } from '@web/contexts/MvvmContext';

const NavItem = ({ item, level }: { item: any, level: number }) => {
  const theme = useTheme();
  const { pathname } = useLocation();

  const mvvm = useMvvm();

  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps: any = {
    component: forwardRef<HTMLAnchorElement>((props, ref) => <Link ref={ref} {...props} to={item.url}
                                                                   target={itemTarget} />)
  };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const itemHandler = (id: string) => {
    mvvm.menu.activeItem([id]);
  };

  const Icon = item.icon;

  const isSelected = mvvm.menu.openItem.findIndex((id: string) => id === item.id) > -1;
  useEffect(() => {
    if (pathname.includes(item.url)) {
      mvvm.menu.activeItem([item.id]);
    }
  }, [pathname]);

  const textColor = 'text.primary';
  const iconSelectedColor = 'primary.main';

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      onClick={() => itemHandler(item.id)}
      selected={isSelected}
      sx={{
        zIndex: 1201,
        pl: mvvm.menu.drawerOpen ? `${level * 28}px` : 1.5,
        py: !mvvm.menu.drawerOpen && level === 1 ? 1.25 : 1,
        ...(mvvm.menu.drawerOpen && {
          '&:hover': {
            bgcolor: 'primary.lighter'
          },
          '&.Mui-selected': {
            bgcolor: 'primary.lighter',
            borderRight: `2px solid ${theme.palette.primary.main}`,
            color: iconSelectedColor,
            '&:hover': {
              color: iconSelectedColor,
              bgcolor: 'primary.lighter'
            }
          }
        }),
        ...(!mvvm.menu.drawerOpen && {
          '&:hover': {
            bgcolor: 'transparent'
          },
          '&.Mui-selected': {
            '&:hover': {
              bgcolor: 'transparent'
            },
            bgcolor: 'transparent'
          }
        })
      }}
    >
      {Icon && (
        <ListItemIcon
          sx={{
            minWidth: 28,
            color: isSelected ? iconSelectedColor : textColor,
            ...(!mvvm.menu.drawerOpen && {
              borderRadius: 1.5,
              width: 36,
              height: 36,
              alignItems: 'center',
              justifyContent: 'center',
              '&:hover': {
                bgcolor: 'secondary.lighter'
              }
            }),
            ...(!mvvm.menu.drawerOpen &&
              isSelected && {
                bgcolor: 'primary.lighter',
                '&:hover': {
                  bgcolor: 'primary.lighter'
                }
              })
          }}
        >
          <Icon style={{ fontSize: mvvm.menu.drawerOpen ? '1rem' : '1.25rem', color: theme.palette.primary.main }} />
        </ListItemIcon>
      )}
      {(mvvm.menu.drawerOpen || (!mvvm.menu.drawerOpen && level !== 1)) && (
        <ListItemText
          primary={
            <Typography variant="h6" sx={{ color: isSelected ? iconSelectedColor : textColor }}>
              {item.title}
            </Typography>
          }
        />
      )}
      {(mvvm.menu.drawerOpen || (!mvvm.menu.drawerOpen && level !== 1)) && item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number
};

export default observer(NavItem);
