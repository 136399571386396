import { action, makeObservable, observable } from 'mobx';

export class MenuViewModel {

  @observable openItem: string[] = ['dashboard'];
  @observable defaultId = 'dashboard';
  @observable openComponent = 'buttons';
  @observable drawerOpen = true;
  @observable componentDrawerOpen = true;

  constructor() {
    makeObservable(this);
  }

  @action
  activeItem(value: string[]) {
    this.openItem = value;
  }

  @action
  activeComponent(value: string) {
    this.openComponent = value;
  }

  @action
  openDrawer(value: boolean) {
    this.drawerOpen = value;
  }

  @action
  openComponentDrawer(value: boolean) {
    this.componentDrawerOpen = value;
  }
}
