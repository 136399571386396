import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

export async function call<T extends (...args: any[]) => Promise<any>>(
  fn: T, ...args: Parameters<T>
): Promise<ReturnType<T>> {
  try {
    return await fn(...args);
  } catch (error: any) {
    handleAxiosError(error);
    throw error;
  }
}

function handleAxiosError(error: AxiosError) {
  switch (error.response?.status) {
    case 400:
      handle400Error(error);
      break;
    case 403:
      handle403Error(error);
      break;
    case 401:
      break;
    default:
      toast.error(`API Error: ${(error.response?.data as any)?.message || error.message}`);
  }
}

function handle403Error(error: AxiosError) {
  toast.error(`Insufficient Permissins: ${(error.response?.data as any)?.message}`);
}

function handle400Error(error: AxiosError) {
  const validations = (error.response?.data as any)?.message;
  if (validations.length) validations.forEach((v: string) => toast.error(v, { autoClose: false }));
  else toast.error(validations);
}
