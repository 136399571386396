// assets
import { GoldOutlined, HourglassOutlined, MedicineBoxOutlined, MessageOutlined } from '@ant-design/icons';

const menus = {
  id: 'group-medicals',
  title: 'Medicals',
  type: 'group',
  children: [
    {
      id: 'appointments',
      title: 'Appointments',
      type: 'item',
      url: '/activities/appointments',
      icon: MessageOutlined,
      breadcrumbs: true,
      fab: false,
      resource: 'appointments'
    }
  ]
};

export default menus;
