import { Box, IconButton, Link, Theme, useMediaQuery } from '@mui/material';
import { GithubOutlined } from '@ant-design/icons';

import Search from './Search';
import Profile from '../../Profile';
import Notification from '../../Notification/Notification';
import MobileSection from './MobileSection';

type HeaderContentProps = {
  showSearch?: boolean;
}

const HeaderContent = ({ showSearch }: HeaderContentProps) => {
  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <>
      <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
        {(!matchesXs && showSearch) && <Search />}
        {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
      </Box>

      <Notification />
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection showSearch={showSearch} />}
    </>
  );
};

export default HeaderContent;
