import { LegoProps } from '@ropha/builder';
import { useMemo } from 'react';
import { WithLego } from '@web/components/organisms/WithLego';

function isInputArray(input: any) {
  return input.name === 'InputArray';
}

export function InputGroup({ value = {}, onChange, inputs = {} }: LegoProps['InputGroup']) {

  function changeHandler(key: string, input: any) {
    onChange && onChange({ ...value, [key]: input });
  }

  const inputArray = useMemo(
    () => Object.entries(inputs as Record<string, any>),
    [inputs]
  );

  return (
    <>
      {inputArray.map(([key, input]) =>
        <WithLego
          grid={input.grid || (isInputArray(input) ? undefined : { xs: 12 })}
          lego={{ ...input, value: value[key], onChange: (v: any) => changeHandler(key, v) }}
          key={key}
        />
      )}
    </>
  );
}
