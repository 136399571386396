import { Autocomplete, debounce, Stack, TextField } from '@mui/material';
import { LegoProps } from '@ropha/builder';
import { useEffect, useMemo, useState } from 'react';
import { WithLego } from '@web/components/organisms/WithLego';


export function SelectInput({ label, value = '', onChange, renderer, getOptions, getLabel }: LegoProps['SelectInput']) {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [input, setInput] = useState<string>('');

  const search = useMemo(() => debounce((input: string) => {
    setLoading(true);
    return getOptions(input)
      .then(data => setOptions(data))
      .finally(() => setLoading(false));
  }, 400), [getOptions]);

  useEffect(() => {
    search(input);
  }, [input]);

  return (
    <Stack>
      <Autocomplete
        disableClearable
        options={options}
        loading={loading}
        value={value}
        getOptionLabel={getLabel}
        isOptionEqualToValue={() => false}
        filterOptions={(x) => x}
        noOptionsText={input.trim().length ? 'No options' : 'Start typing...'}
        renderOption={
          (props, option) =>
            <li {...props} style={{ padding: 0 }}>
              <WithLego lego={renderer(option)} />
            </li>
        }
        onChange={
          (_, value) => onChange(value)
        }
        onInputChange={
          (_, input) => setInput(input)
        }
        renderInput={(params) => (
          <TextField {...params} variant="filled" label={label} />
        )}
      />
    </Stack>
  );
}
