import { DialogActions, Divider, Grid, Stack } from '@mui/material';
import MainCard from '@web/components/atoms/cards/MainCard';
import { observer } from 'mobx-react-lite';
import Text from '@web/components/atoms/displays/Text';
import { useCallback, useEffect, useState } from 'react';
import { api, useApi } from '@web/hooks/useApi';
import { UserDetailsDto } from '@web/gen/api';
import LinearProgress from '@mui/material/LinearProgress';
import { DisplayUtils } from '@web/utils/display.utils';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { CloseCircleOutlined } from '@ant-design/icons';

type UserDeleteConfirmation = { id?: string }

function UserDeleteConfirmation({ id }: UserDeleteConfirmation) {
  const [details, setDetails] = useState<UserDetailsDto>();
  const [getUserDetails, isFetchingDetails] = useApi(api.users.details);
  const [removeUser, isRemoving] = useApi(api.users.remove);
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) return;
    getUserDetails(id).then(setDetails);
  }, [id]);

  const onConfirm = useCallback(() => removeUser(id!).then(() => navigate(-1)), [id]);

  return (
    (isFetchingDetails || !details) ?
      <LinearProgress variant="indeterminate" /> :
      <MainCard sx={{ bgcolor: 'grey.50', m: 0, width: '100%' }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Text variant={'paragraph'}
                  content={`Are you wish you wish to remove "${DisplayUtils.fullName(details)}" ?`} />
            <Text variant={'paragraph-bold'} content={`This cannot be undone.`} />
          </Grid>
          <Divider />
          <Grid item xs={12}>
            <DialogActions>
              <Stack direction="row" spacing={3}>
                <LoadingButton
                  loading={isRemoving} disabled={isRemoving} onClick={onConfirm}
                  variant="contained" color="primary" disableElevation sx={{ borderRadius: 0 }}
                  endIcon={<CloseCircleOutlined />}
                >
                  Yes, Proceed
                </LoadingButton>
              </Stack>
            </DialogActions>
          </Grid>
        </Grid>
      </MainCard>
  );
}

export default observer(UserDeleteConfirmation);
