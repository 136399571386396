import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

export default function WizardsSpeedDial({ menus }: { menus: any[] }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return menus.length > 0 ?
    (
      <>
        <Backdrop open={open} sx={{ zIndex: 10 }} />
        <SpeedDial
          ariaLabel="Create resource fab"
          sx={{ position: 'absolute', top: 67, right: 50 }}
          FabProps={{ sx: { bgcolor: 'primary.main' } }}
          icon={<SpeedDialIcon />}
          direction={'left'}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          {menus.map((menu) => (
            <SpeedDialAction
              key={menu.id}
              icon={<ItemIcon icon={menu.icon} />}
              FabProps={{ href: menu.fab }}
              tooltipTitle={menu.title}
              onClick={handleClose}
            />
          ))}
        </SpeedDial>
      </>
    ) :
    null;
}

function ItemIcon(props: { icon: any }) {
  const Icon = props.icon;
  return <Icon style={{ color: '#ff6969' }} />;
}
