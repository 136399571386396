import { Outlet } from 'react-router-dom';
import Header from '@web/components/molecules/Header';
import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { withUserLoading } from '@web/hocs/withUserLoading';
import { withNotifications } from '@web/hocs/withNotifications';

const MinimalLayout = () => (
  <Box sx={{ display: 'flex', width: '100%' }}>
    <Header showSearch={false} showLogo />
    <Box component="main" sx={{ width: '100%', flexGrow: 1, paddingTop: '60px !important' }}>
      <Outlet />
    </Box>
  </Box>
);

export default observer(withUserLoading(withNotifications(MinimalLayout)));
