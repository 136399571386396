import { CardMedia, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import MainCard from '@web/components/atoms/cards/MainCard';
import { useAuthUserProfilePic } from '@web/hooks/useAuthUserProfilePic';
import { useAuthUserName } from '@web/hooks/useAuthUserName';
import { observer } from 'mobx-react-lite';
import { useMvvm } from '@web/contexts/MvvmContext';
import { DisplayUtils } from '@web/utils/display.utils';
import { AuthenticatedUserEntity } from '@web/gen/api';

function NavCard() {
  const profilePic = useAuthUserProfilePic();
  const name = useAuthUserName();

  const mvvm = useMvvm();

  return (
    <MainCard sx={{ bgcolor: 'grey.50', m: 0, width: '100%' }}>
      <Stack alignItems="center" spacing={2.5}>
        <CardMedia component="img" image={profilePic} sx={{ width: 112, borderRadius: '50%' }} />
        <Stack alignItems="center">
          <Typography variant="h5">{name}</Typography>
          {!mvvm.user.compositeRoles?.length ?
            <Typography variant="subtitle2" color="secondary" sx={{ textTransform: 'capitalize' }}>
              {DisplayUtils.enumToHuman(mvvm.user.userType.toString(), true)}
            </Typography> :
            <FormControl sx={{ m: 1, mt: 2, minWidth: 120 }} size="small">
              <InputLabel
                sx={{ textTransform: 'capitalize' }}>{DisplayUtils.enumToHuman(mvvm.user.userType.toString(), true)}</InputLabel>
              <Select
                value={mvvm.user.assumedRole}
                sx={{ textTransform: 'capitalize' }}
                label="Age"
                onChange={(e) => {
                  console.log('value', e.target.value);
                  mvvm.user.assumeRole(e.target.value as AuthenticatedUserEntity['userType']);
                }}
              >
                {mvvm.user.compositeRoles.map((role) => (
                  <MenuItem key={role} value={role} sx={{ textTransform: 'capitalize' }}>
                    {DisplayUtils.enumToHuman(role.toString(), true)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          }
        </Stack>
      </Stack>
    </MainCard>
  );
}

export default observer(NavCard);
