import {
  Avatar,
  Button,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';
import Text from '@web/components/atoms/displays/Text';
import React from 'react';
import { ReportEntity } from '@web/gen/api';
import { AntdIcon } from '@web/components/atoms/AntdIcon';
import MainCard from '@web/components/atoms/cards/MainCard';

type LabResultsProps = {
  labs: ReportEntity['labRequests']
}

function LabResults({ labs }: LabResultsProps) {
  return (
    <Stack spacing={1.5} ml={2} mb={3}>
      <Text variant="paragraph-bold" content="Lab Requests"></Text>
      <MainCard sx={{ mt: 2, width: '100%' }} content={false}>
        <List
          component="nav"
          sx={{
            px: 0, py: 0,
            '& .MuiListItemButton-root': {
              py: 1.5,
              '& .MuiAvatar-root': {
                width: 36,
                height: 36,
                fontSize: '1rem'
              },
              '& .MuiListItemSecondaryAction-root': {
                mt: 0.75,
                ml: 1,
                top: 'auto',
                right: 'auto',
                alignSelf: 'flex-start',
                transform: 'none',
                position: 'relative'
              }
            }
          }}
        >
          {labs.map(
            (lab, index) => (
              <ListItemButton divider key={index}>
                <ListItemAvatar>
                  <Avatar sx={{
                    color: lab.results ? `${fileColor(lab.results)}.main` : 'grey',
                    bgcolor: lab.results ? `${fileColor(lab.results)}.lighter` : 'white'
                  }}>
                    <AntdIcon name={fileIcon(lab.results)} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography variant="subtitle1">{lab.details}</Typography>}
                  secondary={lab.type}
                />
                {!!lab.results &&
                  <ListItemSecondaryAction>
                    <Button
                      variant="outlined" size={'small'}
                      startIcon={<AntdIcon name={'ExportOutlined'} />}
                      href={lab.results}
                      download={false}
                      // href={`/preview?${new URLSearchParams({ url: lab.results }).toString()}`}
                      target={'_blank'}
                    >
                      View
                    </Button>
                  </ListItemSecondaryAction>
                }
              </ListItemButton>
            )
          )}
        </List>
      </MainCard>
    </Stack>
  );
}

function fileIcon(fileUrl: string | undefined) {
  if (!fileUrl) return 'FileUnknownOutlined';
  const extension = fileUrl.split('.').pop()?.toLowerCase();
  switch (extension) {
    case 'pdf':
      return 'FilePdfOutlined';
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'svg':
    case 'gif':
    case 'web':
      return 'FileImageOutlined';
    case 'zip':
      return 'FileZipOutlined';
    case 'dcm':
      return 'FileProtectOutlined';
    default:
      return 'FileOutlined';
  }
}

function fileColor(fileUrl: string) {
  const extension = fileUrl.split('.').pop()?.toLowerCase();
  switch (extension) {
    case 'pdf':
      return 'error';
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'svg':
    case 'gif':
    case 'web':
      return 'success';
    case 'zip':
      return 'info';
    case 'dcm':
      return 'info';
    default:
      return 'info';
  }
}

export default LabResults;
