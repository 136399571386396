import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';

export const Auth0ProviderWithNavigate = (props: any) => {

  const domain = import.meta.env.VITE_AUTH0_DOMAIN;
  const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;

  if (!domain || !clientId) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        prompt: 'login',
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
        redirect_uri: new URL('callback', window.location.origin).toString()
      }}
    >
      {props.children}
    </Auth0Provider>
  );
};
