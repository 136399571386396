import React from 'react';
import { Box, LinearProgress } from '@mui/material';
import { LegoProps } from '@ropha/builder';
import Text from '@web/components/atoms/displays/Text';

const ProgressBar = ({ value }: LegoProps['ProgressBar']) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" marginRight={1}>
        <LinearProgress variant="determinate" value={value} color="primary" />
      </Box>
      <Box minWidth={35}>
        <Text variant="text" content={`${value}%`} />
      </Box>
    </Box>
  );
};

export default ProgressBar;
