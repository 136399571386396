import { useEffect, useState } from 'react';
import { EnhancedForms } from '@web/components/molecules/EnhancedForms';
import { LegoBlocks } from '@web/components/lego/blocks';
import { observer } from 'mobx-react-lite';
import { useMvvm } from '@web/contexts/MvvmContext';
import { AvailabilityEntity } from '@web/gen/api';
import { DialogActions, Divider, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CheckOutlined } from '@ant-design/icons';
import LinearProgress from '@mui/material/LinearProgress';
import { api, useApi } from '@web/hooks/useApi';

function AvailabilitySettings() {
  const [loading, setLoading] = useState(false);
  const [availability, setAvailability] = useState<AvailabilityEntity[]>([]);
  const [createAppointment, isCreating] = useApi(api.availabilities.create);

  const mvvm = useMvvm();

  function saveAvailability() {
    createAppointment({ availabilities: availability })
      .finally(() => loadResources());
  }

  function loadResources() {
    setLoading(true);
    mvvm.user.availabilities()
      .then(setAvailability)
      .finally(() => setLoading(false));
  }

  useEffect(() => loadResources(), []);

  return (
    loading ?
      <LinearProgress variant="indeterminate" /> :
      <>
        <DialogActions>
          <Stack direction="row" spacing={3}>
            <LoadingButton
              loading={isCreating} disabled={isCreating} onClick={saveAvailability}
              variant="contained" color="primary" disableElevation sx={{ borderRadius: 0 }}
              endIcon={<CheckOutlined />}
            >
              Submit
            </LoadingButton>
          </Stack>
        </DialogActions>
        <Divider />
        <EnhancedForms
          forms={LegoBlocks.AvailabilityForm}
          onChange={({ availability }) => setAvailability(availability)}
          value={{ availability }}
        />
      </>
  );
}

export default observer(AvailabilitySettings);
