import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useMvvm } from '@web/contexts/MvvmContext';
import { observer } from 'mobx-react-lite';
import { getTenant } from '@web/data/tenants';

export type TenantTheme = {
  title: string;
  color: string;
  logoSquare?: string | null;
  logoBanner: string;
}

const TenantThemeContext = createContext<[TenantTheme, (_: TenantTheme) => void]>(
  [{} as TenantTheme, () => void 0]
);

const TenantThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const mvvm = useMvvm();
  const [tenant, setTenant] = useState<TenantTheme>(getTenant());

  useEffect(() => {
    const tenant = mvvm.user.tenantTheme;
    if (tenant) setTenant(tenant);
  }, [mvvm.user.user]);

  return (
    <TenantThemeContext.Provider value={[tenant, setTenant]}>
      {children}
    </TenantThemeContext.Provider>
  );
};

export const useTenantTheme = () => useContext(TenantThemeContext);

export default observer(TenantThemeProvider);
