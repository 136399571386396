import { IBaseBuilder, INestedBuilder, IUnionBuilder } from './builders.interface';

abstract class BuilderProxy<T> {
  protected readonly _proxy: any;

  constructor(target: Partial<T> = {}) {
    this._proxy = new Proxy(target, { get: this.proxyHandler.bind(this) });
    return this._proxy;
  }

  private proxyHandler(target: Partial<T>, prop: string) {
    return prop === 'build' ?
      () => target :
      (value?: any) => this.handler(target, prop, value);
  }

  abstract handler(target: Partial<T>, prop: string, value: any): any;
}

class BaseBuilder<T> extends BuilderProxy<T> {
  handler(target: Partial<T>, prop: string, value: any): any {
    target[prop as keyof T] = value;
    return this._proxy;
  }
}

abstract class NestedBuilder<T> extends BuilderProxy<T> {
  handler(_: Partial<T>, prop: string): any {
    return new BaseBuilder(this.initializer(prop));
  }

  abstract initializer(prop: string): any
}

class UnionBuilder<T, TUnionField extends keyof T = never> extends NestedBuilder<T> {
  // protected readonly key: TUnionField;

  constructor(protected readonly key: TUnionField, target: Partial<T> = {}) {
    super(target);
  }

  override initializer(prop: string): any {
    const built = this._proxy.build();
    return { [built.key]: prop };
  }
}

export function createBuilder<T, TRepeat extends boolean = true>(target?: Partial<T>): IBaseBuilder<T, TRepeat> {
  return new BaseBuilder<T>(target) as unknown as IBaseBuilder<T, TRepeat>;
}

export const createNestedBuilder = <T, TRepeat extends boolean = true>(
  init: NestedBuilder<T>['initializer'], target?: Partial<T>
): INestedBuilder<T, TRepeat> => {
  return new class extends NestedBuilder<T> {
    override initializer(prop: string): any {
      return init(prop);
    }
  }(target) as unknown as INestedBuilder<T, TRepeat>;
};

export const createUnionBuilder = <T, TUnionField extends keyof T, TRepeat extends boolean = true>(
  key: TUnionField, target?: Partial<T>
): IUnionBuilder<T, TUnionField, TRepeat> => {
  return new UnionBuilder<T, TUnionField>(key, target) as unknown as IUnionBuilder<T, TUnionField, TRepeat>;
};

