import { Box, Typography } from '@mui/material';
import NavGroup from './NavGroup';
import { useMvvm } from '@web/contexts/MvvmContext';
import { observer } from 'mobx-react-lite';

const Navigation = () => {
  const mvvm = useMvvm();
  const navGroups = mvvm.user.navigation.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default observer(Navigation);
