import { Stack, TextField } from '@mui/material';
import { LegoProps } from '@ropha/builder';

export function TextInput({ label, value = '', onChange }: LegoProps['TextInput']) {
  return (
    <Stack>
      <TextField
        label={label} value={value} variant="filled"
        onChange={e => onChange && onChange(e.target.value)}
      />
    </Stack>
  );
}
