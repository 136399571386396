import { Box, Grid } from '@mui/material';
import { WithLego } from '@web/components/organisms/WithLego';
import { Legos } from '@ropha/builder';

export type FormsProps = {
  forms: Legos['InputGroup']
  value?: Record<string, any>
  onChange: (v: any) => void;
  validations?: any[]
  rowSpacing?: number
  columnSpacing?: number
}

export function EnhancedForms({ forms, rowSpacing = 4, columnSpacing = 2, value = {}, onChange }: FormsProps) {
  return (
    <Box>
      <Grid container rowSpacing={rowSpacing} columnSpacing={columnSpacing}>
        <WithLego lego={{ ...forms, value, onChange }} />
      </Grid>
    </Box>
  );
}
