import * as ReactDOM from 'react-dom/client';

import App from './App';
import * as Sentry from '@sentry/react';
import { Auth0ProviderWithNavigate } from '@web/guards/Auth0ProviderWithNavigate';
import { BrowserRouter } from 'react-router-dom';
import { MvvmProvider } from '@web/contexts/MvvmContext';
import { register } from '@web/serviceWorker';

import 'simplebar/dist/simplebar.css';
import 'react-toastify/dist/ReactToastify.css';
import 'animate.css/animate.css';
import '@web/assets/overrides.css';

if (import.meta.env.VITE_SENTRY_ENABLED === 'true')
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', import.meta.env.VITE_API_URL],
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0
  });


ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  // <StrictMode>
  <Auth0ProviderWithNavigate>
    <MvvmProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MvvmProvider>
  </Auth0ProviderWithNavigate>
  // </StrictMode>
);

register();
